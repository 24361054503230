<i18n>
ru:
  animator: 'Аниматор для детей:'
  available: есть
  bookTable: Забронировать столик
  notAvailable: нет
  parking: 'Парковка:'
  payCard: 'Оплата кредитными картами:'
  tableReservation: 'Бронь столов:'
  wiFi: 'Wi-fi:'
ua:
  animator: 'Аніматор для дітей:'
  available: є
  bookTable: Забронювати столик
  notAvailable: немає
  parking: 'Парковка:'
  payCard: 'Оплата кредитними картками:'
  tableReservation: 'Бронювання столів:'
  wiFi: 'Wi-fi:'
us:
  animator: 'Kids entertainment:'
  available: available
  bookTable: Book a table
  notAvailable: not available
  parking: 'Parking:'
  payCard: 'Credit card payment:'
  tableReservation: 'Table reservation:'
  wiFi: 'Wi-fi:'
</i18n>

<template>
  <section v-if="hall">
    <div class="v-row v-restaurant-single">
      <div
        v-if="hall.Photos.length > 0"
        class="v-col-12 v-col-md-7 v-col-lg-8 v-mb-xxl"
      >
        <common-gallery
          :auto-play-delay="appConfig.VueSettingsPreRun.HallsAutoscrollDelaySec * 1000"
          :images="hall.Photos"
          :show-thumbs="false"
        />
      </div>
      <div class="v-col-12 v-col-md-5 v-col-lg-4 v-mb-xxl v-d-flex v-flex-column">
        <div class="v-mb-sm">
          <page-restaurant-address :hall="hall" />
        </div>
        <page-restaurant-info :hall="hall" />
        <div class="v-restaurant-single--hr v-py-sm">
          <hr />
        </div>
        <page-restaurant-details :hall="hall" />
        <page-restaurant-buttons
          :hall="hall"
          :reservation-callback="openPopup"
        />
      </div>
      <div
        class="v-col-12"
        v-html="sanitize(hall.Description)"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Hall } from '~types/pageStore'

const pageStore = usePageStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { sanitize } = useI18nSanitized()
const { isLinkContains } = useUrl()

const hall = ref<Hall | undefined>()

onMounted(async () => {
  if (import.meta.client) await pageStore.initRestaurants()

  hall.value = pageStore.RestaurantsSorted.find((restaurant) => isLinkContains(restaurant.DetailsLink))
})

function openPopup(): void {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'tableReservationPopup'
  })
}
</script>

<style lang="scss">
@use '~/assets/extend';
@use '~/assets/variables';

.v-restaurants-link {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: baseline;
  gap: 4px;

  .v-icon {
    width: 0.85rem;
    height: 0.85rem;
    display: inline-flex;
    .v-svg-arrow-prev {
      display: flex;
      @extend .v-img-fluid;
    }
  }
}

.v-restaurant-single {
  &--hr {
    display: flex;
    flex-direction: column;
    justify-content: center;

    hr {
      width: 100%;
      color: variables.$BodyTextColor;
      opacity: 0.25;
    }
  }
}
</style>
