<i18n>
ru:
  topPurchased: Популярные
  recentlyViewed: Недавно просмотренные
  usuallyBoughtWithProduct: С этим товаром также покупают
ua:
  topPurchased: Популярні
  recentlyViewed: Нещодавно переглянуті
  usuallyBoughtWithProduct: З цим товаром також купують
us:
  topPurchased: Popular
  recentlyViewed: Recently viewed
  usuallyBoughtWithProduct: People also buy with this
</i18n>

<template>
  <div
    v-if="related"
    class="v-related"
  >
    <template v-if="!onMainPage && related.UsuallyBoughtWithProduct.length > 0">
      <template v-if="appConfig.VueSettingsPreRun.MenuRelatedShowAsList">
        <div
          class="v-related-title"
          v-html="translate('related.usuallyBoughtWithProduct')"
        />
        <common-cards-flex-mesh
          :class-name="`v-menu-related-${appConfig.VueSettingsPreRun.MenuRelatedItemsMax}`"
          :items="related.UsuallyBoughtWithProduct"
          :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
          :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
        >
          <template #item="usuallyBoughtWithProduct: ProductInList">
            <menu-product-in-list-item-card-by-type :product="usuallyBoughtWithProduct" />
          </template>
        </common-cards-flex-mesh>
      </template>
      <arora-carousel
        v-else
        :items="related.UsuallyBoughtWithProduct"
        :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
        :show-part-of-next-slide="false"
        :title="translate('related.usuallyBoughtWithProduct')"
        navigation="top"
        snap-align="start"
      >
        <template #item="usuallyBoughtWithProduct: ProductInList">
          <menu-product-in-list-item-card-by-type
            disable-lazy
            :product="usuallyBoughtWithProduct"
          />
        </template>
      </arora-carousel>
    </template>
    <template
      v-if="
        ((onMainPage && appConfig.VueSettingsPreRun.MainPageLastSeenEnable) || !onMainPage) &&
        related.RecentlyViewed.length > 0
      "
    >
      <template v-if="appConfig.VueSettingsPreRun.MenuRelatedShowAsList">
        <div
          class="v-related-title"
          v-html="translate('related.recentlyViewed')"
        />

        <common-cards-flex-mesh
          :class-name="`v-menu-related-${appConfig.VueSettingsPreRun.MenuRelatedItemsMax}`"
          :items="related.RecentlyViewed"
          :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
          :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
        >
          <template #item="recentlyViewed: ProductInList">
            <menu-product-in-list-item-card-by-type :product="recentlyViewed" />
          </template>
        </common-cards-flex-mesh>
      </template>
      <arora-carousel
        v-else
        :items="related.RecentlyViewed"
        :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
        :show-part-of-next-slide="false"
        :title="translate('related.recentlyViewed')"
        navigation="top"
        snap-align="start"
      >
        <template #item="recentlyViewed: ProductInList">
          <menu-product-in-list-item-card-by-type
            disable-lazy
            :product="recentlyViewed"
          />
        </template>
      </arora-carousel>
    </template>
    <template
      v-if="
        ((onMainPage && appConfig.VueSettingsPreRun.MainPagePopularEnable) || !onMainPage) &&
        related.TopPurchased.length > 0
      "
    >
      <template v-if="appConfig.VueSettingsPreRun.MenuRelatedShowAsList">
        <div
          class="v-related-title"
          v-html="translate('related.topPurchased')"
        />

        <common-cards-flex-mesh
          :class-name="`v-menu-related-${appConfig.VueSettingsPreRun.MenuRelatedItemsMax}`"
          :items="related.TopPurchased"
          :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
          :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
        >
          <template #item="topPurchased: ProductInList">
            <menu-product-in-list-item-card-by-type :product="topPurchased" />
          </template>
        </common-cards-flex-mesh>
      </template>
      <arora-carousel
        v-else
        :items="related.TopPurchased"
        :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
        :show-part-of-next-slide="false"
        :title="translate('related.topPurchased')"
        navigation="top"
        snap-align="start"
      >
        <template #item="topPurchased: ProductInList">
          <menu-product-in-list-item-card-by-type
            disable-lazy
            :product="topPurchased"
          />
        </template>
      </arora-carousel>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { MenuRelated, ProductInList } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { productId } = defineProps<{
  onMainPage?: boolean
  productId?: GUID | undefined
}>()

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { makeDefaultOption } = useProduct()
const router = useRouter()

const related = ref<MenuRelated | null>(null)

onMounted(async () => {
  if (import.meta.client) related.value = await menuStore.getRelated(productId)

  if (related.value) {
    for (const menuItem of related.value.UsuallyBoughtWithProduct) {
      makeDefaultOption(menuItem)
    }

    for (const menuItem of related.value.RecentlyViewed) {
      makeDefaultOption(menuItem)
    }

    for (const menuItem of related.value.TopPurchased) {
      makeDefaultOption(menuItem)
    }
  }
})

router.afterEach(async () => {
  if (import.meta.client) related.value = await menuStore.refreshRelated(productId)
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/extend';
@use '~/assets/mixins';

.v-related {
  width: 100%;
  margin-bottom: 10px;
  @include mixins.swiper-button-products;

  .carousel {
    @extend .v-pm-shadow;
  }
}

.v-related-title {
  font-weight: 600;
  font-size: variables.$TextSizeH2;

  @include mixins.sm {
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
  }
}
</style>
