<template>
  <div class="v-container">
    <cart-old-order-complete :order-id="route.params.id as GUID" />
  </div>
</template>

<script setup lang="ts">
import type { GUID } from '@arora/common'

const route = useRoute()
</script>
