<i18n>
ru:
  button: Забронировать столик
ua:
  button: Забронювати столик
us:
  button: Book a table
</i18n>

<template>
  <arora-button
    :label="translate('tableReservationButton.button')"
    @click="async () => await showPopup()"
  />
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()
const popupStore = usePopupStore()

async function showPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'tableReservationPopup'
  })
}
</script>
