<i18n>
ru:
  error: Что-то пошло не так...
  exceptionDetails: Технические подробности
  exceptionSubtitle: "Возникла техническая ошибка сервера, свяжитесь с специалистом"
  exceptionTitle: Что-то пошло не так...
  internalTitle: "Возникла ошибка выполнения скрипта, свяжитесь с специалистом"
  ok: Продолжить
  success: Успешно
  warning: Внимание!
ua:
  error: Щось пішло не так...
  exceptionDetails: Технічні подробиці
  exceptionSubtitle: "Виникла технічна помилка сервера, зверніться до фахівця"
  exceptionTitle: Щось пішло не так...
  internalTitle: "Виникла помилка виконання скрипту, зверніться до фахівця"
  ok: Продовжити
  success: Успішно
  warning: Увага!
us:
  error: Something went wrong...
  exceptionDetails: Technical details
  exceptionSubtitle: "A technical server error occurred, please contact a specialist"
  exceptionTitle: Something went wrong...
  internalTitle: "A script execution error occurred, please contact a specialist"
  ok: Continue
  success: Success
  warning: Attention!
</i18n>

<template>
  <div
    class="v-popup-head v-title v-justify-content-center v-font-weight-600"
    :data-test-id="
      stringIsNullOrWhitespace(dataTestId)
        ? `message-popup-${type}`
        : `message-popup-${type}-${dataTestId}`
    "
  >
    <div
      v-if="type === 'warning'"
      class="v-warning-color"
    >
      <span v-html="title ? sanitize(title) : translate('messagePopup.warning')" />
    </div>
    <div
      v-else-if="type === 'error'"
      class="v-error-color"
    >
      <span v-html="title ? sanitize(title) : translate('messagePopup.error')" />
    </div>
    <div
      v-else-if="type === 'success'"
      class="v-success-color"
    >
      <span v-html="title ? sanitize(title) : translate('messagePopup.success')" />
    </div>
    <div v-else-if="!stringIsNullOrWhitespace(title)">
      <span v-html="sanitize(title)" />
    </div>
  </div>

  <div class="v-popup-body">
    <div
      class="v-message-container"
      :class="[
        `v-message-container--${type}`,
        {
          'v-d-flex': icon
        }
      ]"
      data-test-id="message-popup-text"
    >
      <div class="v-message-container--icon">
        <component
          v-if="icon"
          :is="icon"
        />
      </div>
      <span v-html="translate(message)" />
    </div>
    <div class="v-popup-buttons-block">
      <arora-button
        class-name="v-btn-message v-btn-text v-no-underline v-font-weight-500"
        ignore-settings
        :label="translate('messagePopup.ok')"
        data-test-id="message-popup-button"
        @click="close"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MessagePopupProps } from '~types/props'

import { useCommon } from '@arora/common'

defineProps<MessagePopupProps>()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const popupStore = usePopupStore()

function close(): void {
  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-content {
  .v-popup-body {
    .v-popup-buttons-block {
      text-align: right;
      margin-top: 20px;
      justify-content: flex-end;

      .v-btn-message {
        color: variables.$BodyTextColorLight;
        font-size: 1.1rem;
      }
    }
  }
}

.v-message-container {
  word-wrap: anywhere;
  gap: 10px;

  &--success,
  &--warning {
    .v-message-container--icon {
      color: variables.$PrimaryBackgroundColor;
    }
  }

  &--error {
    .v-message-container--icon {
      color: variables.$ErrorColor;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }
}
</style>
