<i18n>
ru:
  delivery: Курьером
  inHall: В зале
  orderType: Тип заказа
  selfService: Самовывоз
ua:
  delivery: Курь'єром
  inHall: В залі
  orderType: Тип замовлення
  selfService: Самовивіз
us:
  delivery: Courier delivery
  inHall: In restaurant hall
  orderType: Order type
  selfService: Self-service
</i18n>

<template>
  <arora-option-slider
    v-if="loaded"
    :deviation="isInHeader ? 1 : 3"
    :height="isInHeader ? 32 : 40"
    :index-enabled="indexEnabled"
    :label="translate('orderType.orderType')"
    :only-slider-version="isInHeader && compact"
    :options="switches"
    :theme="isInHeader ? 'header' : null"
    :threshold="asSelect ? 0 : 9"
    data-test-id="order-type-selector"
    v-model:selected="orderType"
  >
    <template #option="option: { value: number | undefined }">
      <div
        v-if="option.value !== undefined"
        :data-test-id="dataTestId(option.value)"
      >
        <div
          v-if="compact || showIconComputed"
          class="v-d-flex"
        >
          <div :class="{ 'v-mr-xs': !compact }">
            <icon-order-type-courier
              v-if="option.value === OrderType.CourierDelivery"
              class="v-order-icon"
            />
            <template v-else-if="option.value === OrderType.NoShipment">
              <icon-order-type-self-service class="v-order-icon" />
            </template>
            <icon-order-type-in-hall
              v-else-if="option.value === OrderType.InHall"
              class="v-order-icon"
            />
          </div>

          <template v-if="!compact">
            <span
              v-if="option.value === OrderType.CourierDelivery"
              v-html="translate('orderType.delivery')"
            />
            <span
              v-else-if="option.value === OrderType.NoShipment"
              v-html="translate('orderType.selfService')"
            />
            <span
              v-else-if="option.value === OrderType.InHall"
              v-html="translate('orderType.inHall')"
            />
          </template>
        </div>
        <template v-else>
          <template v-if="option.value === OrderType.CourierDelivery">
            {{ translate('orderType.delivery') }}
          </template>
          <template v-else-if="option.value === OrderType.NoShipment">
            {{ translate('orderType.selfService') }}
          </template>
          <template v-else-if="option.value === OrderType.InHall">
            {{ translate('orderType.inHall') }}
          </template>
        </template>
      </div>
    </template>
  </arora-option-slider>
  <common-skeleton
    v-else
    :height="isInHeader ? 32 : 40"
  />
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'

import { useWindowSize } from '@arora/common'

import { OrderType } from '~api/consts'

const { isInHeader, orderTypeShowIcons } = defineProps<{
  compact?: boolean
  orderTypeShowIcons?: boolean
  isInHeader?: boolean
}>()

const showIconComputed = computed<boolean>(() => {
  if (isInHeader) return orderTypeShowIcons
  return (
    appConfig.VueSettingsPreRun.OrderTypeShowIconsInCart &&
    !appConfig.VueSettingsPreRun.OrderTypeDisableIcons
  )
})

const loaded = computed(
  () => addressStore.DeliveryAndPay?.data !== null && addressStore.Terminals?.data !== null
)

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const addressStore = useAddressStore()
const clientStore = useClientStore()

onMounted(async () => {
  if (import.meta.client) {
    await clientStore.initClientState()
    await addressStore.initDeliveryAndPay()
    await addressStore.initTerminals()
  }

  if (switches.value.length > 0 && (!orderType.value || orderType.value === OrderType.Default))
    await clientStore.selectOrderType({
      orderType: switches.value[0]
    })
})

const orderType = computed<number | undefined>({
  get() {
    return clientStore.ClientState.data?.OrderType
  },
  set(value) {
    if (value !== undefined)
      clientStore.selectOrderType({
        orderType: value
      })
  }
})

const switches = computed<number[]>(() => {
  const result: number[] = []

  if (!addressStore.DeliveryAndPay.data) return result

  const enableSelfService = addressStore.DeliveryAndPay.data.EnableSelfService
  const enableDeliveryCourier = addressStore.DeliveryAndPay.data.EnableDeliveryCourier
  const enableInHall = addressStore.DeliveryAndPay.data.EnableInHall

  if (enableDeliveryCourier) {
    result.push(OrderType.CourierDelivery)
  }

  if (enableSelfService) {
    result.push(OrderType.NoShipment)
  }

  if (enableInHall) {
    result.push(OrderType.InHall)
  }

  return result
})

function indexEnabled(index: number | undefined): boolean {
  if (index === OrderType.NoShipment) {
    return (
      addressStore.Terminals.data?.some((terminal: Terminal) => terminal.CanOrderSelfService) ?? true
    )
  }

  if (index === OrderType.InHall) {
    return (
      addressStore.Terminals.data?.some(
        (terminal: Terminal) => terminal.InHallSetting.HallTablesNumber > 0
      ) ?? true
    )
  }

  return true
}

const orderTypeShowAsSelect = computed<boolean>(() => appConfig.VueSettingsPreRun.OrderTypeShowAsSelect)
const asSelect = ref<boolean>(false)

const { isExtraSmall } = useWindowSize()

function dataTestId(orderType: number): string | null {
  switch (orderType) {
    case OrderType.CourierDelivery:
      return 'courier-delivery'
    case OrderType.NoShipment:
      return 'no-shipment'
    case OrderType.InHall:
      return 'in-hall'
  }

  return null
}

watch(
  () => isExtraSmall.value,
  (newValue) => {
    asSelect.value = orderTypeShowAsSelect.value || newValue
  }
)

watch(
  () => loaded.value,
  (newValue) => {
    if (newValue && orderType.value === OrderType.Default)
      clientStore.selectOrderType({
        orderType: OrderType.CourierDelivery
      })
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-order-icon {
  width: 18px;
  height: 18px;
  fill: variables.$BodyTextColor;
}
</style>
