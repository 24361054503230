<i18n>
ru:
  ajaxErrorCode1: Слишком много файлов приложено
  ajaxErrorCode2: Слишком большой файл загружен
  ajaxErrorCode3: Ошибка ввода CAPTCHA
  ajaxErrorUnknown: Произошла ошибка, Ваш отзыв не отправлен. Попробуйте снова или
    свяжитесь с нами.
  city: Город
  department: Отдел
  email: Эл. почта
  name: Имя
  personalDataMessage: 'Предоставляя свои персональные данные, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  phoneNumber: Телефон
  popupSubtitle: Напишите нам. Мы обязательно рассмотрим ваше обращение.
  popupTitle: Есть предложения или замечания?
  rating: Оценка
  send: Отправить
  subject: Тема
  successMessage: Отзыв успешно отправлен
  terminal: Точка
  text: Текст отзыва
  textOverSendButton: ''
  title: Заголовок отзыва
  uploadTitle: Загрузите изображение
ua:
  ajaxErrorCode1: Занадто багато файлів додано
  ajaxErrorCode2: Занадто великий файл завантажений
  ajaxErrorCode3: Помилка введення CAPTCHA
  ajaxErrorUnknown: Сталася помилка, ваш відгук не відправлений. Спробуйте знову або
    зв’яжіться з нами.
  city: Місто
  department: Відділ
  email: Ел. пошта
  name: Ім’я
  personalDataMessage: 'Надаючи свої персональні дані, ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  phoneNumber: Телефон
  popupSubtitle: Напишіть нам. Ми обов'язково розглянемо ваше звернення.
  popupTitle: Є пропозиції або зауваження?
  rating: Оцінка
  send: Відправивши
  subject: Тема
  successMessage: Відгук успішно відправлений
  terminal: Точка
  text: Текст відкликання
  textOverSendButton: ''
  title: Заголовок відгуку
  uploadTitle: Завантажте зображення
us:
  ajaxErrorCode1: Too many files attached
  ajaxErrorCode2: Uploaded file is too large
  ajaxErrorCode3: CAPTCHA error
  ajaxErrorUnknown: An error has occurred, your feedback has not been sent. Try again
    or contact us.
  city: City
  department: Department
  email: E-mail
  name: Name
  personalDataMessage: 'By submitting your personal data, you agree to '
  personalDataMessageLink: the terms of personal data processing
  phoneNumber: Phone number
  popupSubtitle: We will gladly consider your feedback.
  popupTitle: You've got any feedback?
  rating: Rating
  send: Send
  subject: Subject
  successMessage: Feedback has been sent successfully
  terminal: Location
  text: Feedback text
  textOverSendButton: ''
  title: Feedback title
  uploadTitle: Upload image
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div class="v-font-weight-700">
      <span v-html="translate('feedbackPopup.popupTitle')" />
    </div>
  </div>
  <div class="v-font-weight-500 v-mb-sm">
    <span v-html="translate('feedbackPopup.popupSubtitle')" />
  </div>

  <div class="v-row">
    <div class="v-col-12 v-col-md-6">
      <v-input
        class-name="v-arora-input"
        required
        v-form-validator="{
          Form: validatorForm,
          Value: feedbackFields.name,
          Required: true,
          Validations: ['text', 'length'],
          MaxLength: 60
        }"
        :label="translate('feedbackPopup.name')"
        v-model:text="feedbackFields.name"
      />
    </div>

    <div class="v-col-12 v-col-md-6">
      <common-phone-input
        :label="translate('feedbackPopup.phoneNumber')"
        :required="appConfig.VueSettingsPreRun.FeedbackNeedToFillPhone"
        :validator-form="validatorForm"
        v-model:phone="feedbackFields.phone"
      />
    </div>

    <div class="v-col-12 v-col-md-6">
      <v-email-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: feedbackFields.email,
          Required: appConfig.VueSettingsPreRun.FeedbackNeedToFillEmail,
          Validations: ['email', 'length'],
          MaxLength: 255
        }"
        :label="translate('feedbackPopup.email')"
        :required="appConfig.VueSettingsPreRun.FeedbackNeedToFillEmail"
        v-model:text="feedbackFields.email"
      />
    </div>

    <div class="v-col-12 v-col-md-6">
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: feedbackFields.caption,
          Required: false,
          Validations: ['length'],
          MaxLength: 100
        }"
        :label="translate('feedbackPopup.title')"
        v-model:text="feedbackFields.caption"
      />
    </div>

    <div
      v-if="subjects.length > 1"
      class="v-col-12 v-col-md-6"
    >
      <arora-select
        required
        :label="translate('feedbackPopup.subject')"
        :options="subjects.map((item) => item.ID)"
        v-model:selected="selectedSubject"
      >
        <template #index="options: { index: number }">
          <option
            :value="options.index"
            v-html="sanitize(subjects[options.index].Title)"
          />
        </template>
      </arora-select>
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.FeedbackShowCities && addressStore.ActiveCities.length > 0"
      class="v-col-12 v-col-md-6"
      v-form-validator="{
        Form: validatorForm,
        Value: feedbackFields.cityId,
        Required: false,
        Validations: ['select']
      }"
    >
      <arora-select
        required
        :label="translate('feedbackPopup.city')"
        :options="addressStore.ActiveCities.map((item) => item.ID)"
        v-model:selected="selectedCity"
      >
        <template #index="options: { index: number }">
          <option
            :value="addressStore.ActiveCities[options.index].ID"
            v-html="sanitize(addressStore.ActiveCities[options.index].Title)"
          />
        </template>
      </arora-select>
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.FeedbackShowDepartments && departments"
      class="v-col-12 v-col-md-6"
      v-form-validator="{
        Form: validatorForm,
        Value: feedbackFields.departmentId,
        Required: false,
        Validations: ['select']
      }"
    >
      <arora-select
        required
        :label="translate('feedbackPopup.department')"
        :options="departments.map((item) => item.ID)"
        v-model:selected="selectedDepartment"
      >
        <template #index="options: { index: number }">
          <option
            :value="departments[options.index].ID"
            v-html="sanitize(departments[options.index].Name)"
          />
        </template>
      </arora-select>
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.FeedbackShowTerminals && terminals"
      class="v-col-12 v-col-md-6"
      v-form-validator="{
        Form: validatorForm,
        Value: feedbackFields.terminalId,
        Required: false,
        Validations: ['select']
      }"
    >
      <arora-select
        required
        :label="translate('feedbackPopup.terminal')"
        :options="terminals.map((item) => item.ID)"
        v-model:selected="selectedTerminal"
      >
        <template #index="options: { index: number }">
          <option
            :value="terminals[options.index].ID"
            v-html="sanitize(terminals[options.index].Address)"
          />
        </template>
      </arora-select>
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.FeedbackShowRating"
      class="v-col-12 v-d-flex v-flex-column v-align-items-start v-py-sm"
    >
      <label class="for-form-control">
        <span v-html="translate('feedbackPopup.rating')" />
      </label>
      <div
        v-if="appConfig.VueSettingsPreRun.FeedbackShowAsStars"
        class="v-feedback-popup-star-mark"
      >
        <template v-if="feedbackFields.rating < 5">
          <icon-general-star
            v-for="index in Array.from({ length: 5 - feedbackFields.rating }, (_v, i) => 5 - i)"
            :key="`rate${index}`"
            class="v-feedback-popup-star-mark-single"
            @click="clickOnRating(index)"
          />
        </template>
        <icon-general-star
          v-for="index in Array.from(
            { length: feedbackFields.rating },
            (_v, i) => feedbackFields.rating - i
          )"
          :key="`rate${index}`"
          class="v-feedback-popup-star-mark-single selected"
          @click="clickOnRating(index)"
        />
      </div>
      <div
        v-else
        class="v-feedback-popup-smile-mark"
      >
        <icon-face-frown
          class="v-feedback-popup-smile-mark-single v-feedback-worst"
          :class="{ selected: feedbackFields.rating === 1 }"
          @click="clickOnRating(1)"
        />
        <icon-face-sad
          class="v-feedback-popup-smile-mark-single v-feedback-bad"
          :class="{ selected: feedbackFields.rating === 2 }"
          @click="clickOnRating(2)"
        />
        <icon-face-neutral
          class="v-feedback-popup-smile-mark-single v-feedback-neutral"
          :class="{ selected: feedbackFields.rating === 3 }"
          @click="clickOnRating(3)"
        />
        <icon-face-smile
          class="v-feedback-popup-smile-mark-single v-feedback-good"
          :class="{ selected: feedbackFields.rating === 4 }"
          @click="clickOnRating(4)"
        />
        <icon-face-happy
          class="v-feedback-popup-smile-mark-single v-feedback-best"
          :class="{ selected: feedbackFields.rating === 5 }"
          @click="clickOnRating(5)"
        />
      </div>
    </div>

    <div class="v-col-12">
      <arora-textarea
        v-form-validator="{
          Form: validatorForm,
          Value: feedbackFields.text,
          Required: true,
          Validations: ['length'],
          MaxLength: 255
        }"
        :label="translate('feedbackPopup.text')"
        :textarea-rows="3"
        v-model:text="feedbackFields.text"
      />
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.FeedbackAllowFileUpload"
      class="v-col-12"
    >
      <arora-file-input
        :allowed-types="[MIME.avif, MIME.bmp, MIME.gif, MIME.heic, MIME.jpeg, MIME.png, MIME.webp]"
        :label="translate('feedbackPopup.uploadTitle')"
        :max-bytes="maxBytes"
        v-model:file="feedbackFields.attachment"
      />
    </div>

    <div class="v-col-12">
      <lazy-common-captcha />
    </div>

    <div
      v-if="!stringIsNullOrWhitespace(translate('feedbackPopup.textOverSendButton'))"
      class="v-col-12"
    >
      <span v-html="translate('feedbackPopup.textOverSendButton')" />
    </div>

    <div class="v-col-12">
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="error"
          class="v-error-color"
          v-html="error"
        />
      </transition>
    </div>

    <div
      v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
      class="v-col-12 v-d-flex v-flex-row"
    >
      <span v-html="translate('feedbackPopup.personalDataMessage')" />
      <arora-nuxt-link
        open-in-new-tab
        :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
        :label="translate('feedbackPopup.personalDataMessageLink')"
      />
    </div>

    <div class="v-col-12 v-d-flex v-justify-content-end">
      <arora-button
        class-name="v-btn-lg"
        :disabled="lockButton"
        :label="translate('feedbackPopup.send')"
        @click="async () => await send()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Department, Terminal } from '~types/addressStore'
import type { FeedbackPayload, FeedbackSubjects } from '~types/pageStore'

import {
  type GUID,
  MIME,
  useCommon,
  useValidationStore,
  VEmailInput,
  vFormValidator,
  VInput
} from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthOperationsErrorCode, Guid } from '~api/consts'

const validatorForm = 'feedback'

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const addressStore = useAddressStore()
const pageStore = usePageStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { clean, sanitize, translate } = useI18nSanitized()
const { eventEmit } = useEmitter()
const { dedupeArrayByID, stringIsNullOrWhitespace } = useCommon()

const maxBytes = 200 * 1024

const feedbackFields = ref<FeedbackPayload>({
  attachment: null as File | null,
  caption: undefined as string | undefined,
  cityId: undefined as GUID | undefined,
  departmentId: undefined as GUID | undefined,
  email: undefined as string | undefined,
  name: undefined as string | undefined,
  passedV3: true,
  phone: undefined as string | undefined,
  rating: appConfig.VueSettingsPreRun.FeedbackDefaultRating ?? 1,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null,
  subject: 0,
  terminalId: undefined as GUID | undefined,
  terminalName: undefined as GUID | undefined,
  text: undefined as string | undefined
})
const error = ref<string | null>(null)

onMounted(() => {
  addressStore.initCities().then(() => {
    if (addressStore.ActiveCities.length > 0) {
      selectedCity.value = addressStore.ActiveCities[0].ID
    }
  })

  if (accountStore.isLoggedIn && accountStore.Profile.data) {
    const profileData = accountStore.Profile.data

    feedbackFields.value.name = profileData.Name
    feedbackFields.value.email = profileData.Email
    feedbackFields.value.phone = profileData.Phone
  }

  pageStore.loadFeedbackSubjects().then((values) => (subjects.value = values))
})

const departments = computed<Department[]>(() => {
  return dedupeArrayByID(
    (addressStore.ActiveCities ?? [])
      .find((item) => item.ID === feedbackFields.value.cityId)
      ?.DistrictsPrices?.map((dp) => dp.Departments)
      ?.flat(1)
      ?.filter((item: Department) => {
        return item.Active
      }) ?? []
  )
})

const terminals = computed<Terminal[]>(() => {
  if (!feedbackFields.value.departmentId) return []
  return (
    departments.value
      .find((d) => d.ID === feedbackFields.value.departmentId)
      ?.Terminals?.filter((item: Terminal) => {
        return item.Active
      }) ?? []
  )
})

const lockButton = ref<boolean>(false)

async function send(): Promise<void> {
  lockButton.value = true
  error.value = null

  feedbackFields.value.name = clean(feedbackFields.value.name)
  feedbackFields.value.phone = clean(feedbackFields.value.phone)
  feedbackFields.value.email = clean(feedbackFields.value.email)
  feedbackFields.value.caption = clean(feedbackFields.value.caption)
  feedbackFields.value.text = clean(feedbackFields.value.text)

  feedbackFields.value = await restaurantStore.validateCaptcha(feedbackFields.value)
  if (appConfig.VueSettingsPreRun.FeedbackShowTerminals) {
    feedbackFields.value.terminalName = terminals.value.find((terminal: Terminal) => {
      return terminal.ID === feedbackFields.value.terminalId
    })?.Name
  }

  if (!validationStore.formPassedCheck(validatorForm)) {
    lockButton.value = false

    return
  }
  const code = await pageStore.sendFeedback(feedbackFields.value)
  lockButton.value = false

  switch (code) {
    case 0: {
      popupStore.closePopup()
      await popupStore.showSuccess(translate('feedbackPopup.successMessage'))

      return
    }
    case 1: {
      error.value = translate('feedbackPopup.ajaxErrorCode1')
      eventEmit('v-reset-captcha')
      return
    }
    case 2: {
      error.value = translate('feedbackPopup.ajaxErrorCode2')
      eventEmit('v-reset-captcha')
      return
    }
    case 3: {
      error.value = translate('feedbackPopup.ajaxErrorCode3')
      eventEmit('v-reset-captcha')
      return
    }
    case AuthOperationsErrorCode.CaptchaWasNotVerified:
    case AuthOperationsErrorCode.CaptchaV3Failed: {
      error.value = translate('accountManagement.captchaValidationError')
      eventEmit('v-reset-captcha')
      return
    }
    default: {
      error.value = translate('feedbackPopup.ajaxErrorUnknown')
      eventEmit('v-reset-captcha')
      return
    }
  }
}
function clickOnRating(rating: number): void {
  feedbackFields.value.rating = rating
}

const subjects = ref<FeedbackSubjects[]>([])
const selectedSubject = computed<number>({
  get() {
    return subjects.value.find((item) => item.ID === feedbackFields.value.subject)?.ID ?? -1
  },
  set(newValue: number) {
    feedbackFields.value.subject = newValue
  }
})
const selectedCity = computed<GUID>({
  get() {
    return (
      addressStore.ActiveCities.find((item) => item.ID === feedbackFields.value.cityId)?.ID ?? Guid.Empty
    )
  },
  set(newValue: GUID) {
    feedbackFields.value.cityId = newValue

    if (departments.value.length > 0) {
      selectedDepartment.value = departments.value[0].ID
    }
  }
})
const selectedDepartment = computed<GUID>({
  get() {
    return (
      departments.value.find((item) => item.ID === feedbackFields.value.departmentId)?.ID ?? Guid.Empty
    )
  },
  set(newValue: GUID) {
    feedbackFields.value.departmentId = newValue
    const department = departments.value.find((item) => item.ID === newValue)

    if (department && department.Terminals.length > 0)
      feedbackFields.value.terminalId = department.Terminals[0].ID
  }
})
const selectedTerminal = computed<GUID>({
  get() {
    return terminals.value.find((item) => item.ID === feedbackFields.value.terminalId)?.ID ?? Guid.Empty
  },
  set(newValue: GUID) {
    feedbackFields.value.terminalId = newValue
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-feedback-popup-smile-mark {
  margin-bottom: 1.1rem;
  display: flex;
  flex-direction: row;
  gap: 5px;

  .v-feedback-popup-smile-mark-single {
    cursor: pointer;
    opacity: 0.35;
    border-radius: 50%;
    transition: opacity 0.3s ease-in;

    width: 48px;
    height: 48px;

    fill: none;

    &:hover {
      opacity: 1;
    }

    &.selected {
      opacity: 1;
    }
  }
}

.v-feedback-popup-star-mark {
  direction: rtl;
  margin-bottom: 1.1rem;
  display: flex;
  flex-wrap: nowrap;

  .v-feedback-popup-star-mark-single {
    cursor: pointer;
    color: variables.$BodyTextColor;
    transition: color 0.3s ease-in;
    width: 44px;
    height: 44px;

    &.selected {
      color: variables.$PrimaryBackgroundColor;
    }

    &:hover {
      color: variables.$PrimaryBackgroundColor;
    }

    &:hover ~ .v-feedback-popup-star-mark-single {
      color: variables.$PrimaryBackgroundColor;
    }
  }
}

.v-feedback-popup-button-block {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.v-popup-data-warning {
  padding: 10px;
  font-size: 12px;
  color: variables.$BodyTextColorLight;

  a {
    color: variables.$LinkColor;
  }
}
</style>
