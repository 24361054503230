<i18n>
ru:
  changePassword: Изменение пароля
  confirmNewPassword: Подтвердить пароль
  newPassword: Новый пароль
ua:
  changePassword: Зміна пароля
  confirmNewPassword: Підтвердіть пароль
  newPassword: Новий пароль
us:
  changePassword: Change password
  confirmNewPassword: Confirm password
  newPassword: New password
</i18n>

<template>
  <div class="v-row">
    <div
      class="v-popup-head v-title v-col-12 v-change-password-popup-title v-mb-sm"
      v-html="translate('changePassword.changePassword')"
    />
    <div class="v-col-12 v-d-flex v-flex-column">
      <v-input
        class-name="v-arora-input"
        focus-on-mount
        required
        v-form-validator="{
          Form: validatorForm,
          Value: newPassword,
          Required: true,
          Validations: ['password', 'length'],
          MaxLength: 10
        }"
        :label="translate('changePassword.newPassword')"
        type="password"
        v-model:text="newPassword"
      />
      <v-input
        class-name="v-arora-input"
        required
        v-form-validator="{
          Form: validatorForm,
          Value: newPasswordRepeat,
          Required: true,
          Validations: ['password', 'length'],
          MaxLength: 10
        }"
        :label="translate('changePassword.confirmNewPassword')"
        type="password"
        v-model:text="newPasswordRepeat"
      />
    </div>

    <transition
      appear
      name="fade"
    >
      <div
        v-if="error"
        class="v-col-12 v-error-color"
        v-html="error"
      />
    </transition>

    <arora-button
      class-name="v-btn v-btn-link v-btn-text v-no-underline v-col-5"
      ignore-settings
      :label="translate('editProfile.cancel')"
      @click="async () => await popupStore.closePopup()"
    />
    <arora-button
      class-name="v-col-7"
      :disabled="stringIsNullOrWhitespace(newPassword) || stringIsNullOrWhitespace(newPasswordRepeat)"
      :label="translate('editProfile.save')"
      @click="async () => await save()"
    />
  </div>
</template>

<script setup lang="ts">
import type { ChangePasswordPayload } from '~types/accountStore'

import { useCommon, useValidationStore, vFormValidator, VInput } from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthOperationsErrorCode } from '~api/consts'

const { clean, translate } = useI18nSanitized()

const phoneForLogin = useCookie('phoneForLogin')
const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()

const accountStore = useAccountStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const validatorForm = 'changePassword'

const newPassword = ref<string>('')
const newPasswordRepeat = ref<string>('')

const error = ref<string | null>(null)

async function save(): Promise<void> {
  if (!validationStore.formPassedCheck(validatorForm)) return

  if (newPassword.value !== newPasswordRepeat.value) {
    error.value = translate('accountManagement.passwordsDoesNotMatch')

    return
  }

  const payload: ChangePasswordPayload = {
    country: appConfig.VueSettingsPreRun.SelectedLanguage,
    externalAuthLink: null,
    newPassword: clean(newPassword.value),
    newPasswordRepeat: clean(newPasswordRepeat.value),
    phone: accountStore.Profile.data?.Phone ?? ''
  }

  const response = await accountStore.changePassword(payload)

  switch (response.code) {
    case 0: {
      phoneForLogin.value = accountStore.Profile.data?.Phone ?? null

      await navigateTo(`${appConfig.VueSettingsPreRun.Links.MainLink}?showLoginPopup=true`)

      return
    }
    case AuthOperationsErrorCode.PasswordDoesNotMatchPattern: {
      error.value = translate('accountManagement.passwordsDoesNotMatch')

      return
    }
    case AuthOperationsErrorCode.PasswordsDoesNotMatch: {
      error.value = translate('accountManagement.passwordsDoesNotMatch')

      return
    }
    default:
      popupStore.showException(response)
  }
}

watch(
  [newPassword, newPasswordRepeat],
  ([newPassValue, newPassRepeatValue], [previousPassValue, previousPassRepeatValue]) => {
    if (newPassValue !== previousPassValue || newPassRepeatValue !== previousPassRepeatValue)
      error.value = null
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-change-password-popup-title {
  font-size: variables.$TextSizeH3;
  font-weight: 600;
}
</style>
