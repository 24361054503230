<i18n>
ru:
  cancel: Отмена
  email: Эл. почта
  name: Имя
  noCutlery: Без приборов
  personCount: Количество персон
  phoneNumber: Телефон
  phoneNumberChange: Изменить
  phoneNumberPopupConfirm: Изменить номер телефона
  phoneNumberPopupText: После изменения номера телефона вы войдете в другой личный кабинет.
  phoneNumberPopupTextAdd: Ваш заказ по-прежнему будет в корзине.
ua:
  cancel: Скасування
  email: Ел. пошта
  name: Ім’я
  noCutlery: Без приборів
  personCount: Кількість персон
  phoneNumber: Телефон
  phoneNumberChange: Змінити
  phoneNumberPopupConfirm: Змінити номер телефону
  phoneNumberPopupText: Після зміни номера телефону ви ввійдете в інший особистий кабінет.
  phoneNumberPopupTextAdd: Ваше замовлення як і раніше буде в кошику.
us:
  cancel: Cancel
  email: E-mail
  name: Name
  noCutlery: No cutlery
  personCount: Person count
  phoneNumber: Phone number
  phoneNumberChange: Change
  phoneNumberPopupConfirm: Change phone number
  phoneNumberPopupText: You can change phone number by logging in different account.
  phoneNumberPopupTextAdd: Your order will remain in your cart.
</i18n>

<template>
  <div
    v-if="loaded"
    class="v-row"
  >
    <div class="v-col-12 v-col-md-6 v-col-xl-3">
      <v-input
        class-name="v-arora-input"
        required
        v-form-validator="{
          Form: validatorForm,
          Value: name,
          Required: true,
          Validations: ['text', 'length'],
          MaxLength: 60
        }"
        :label="translate('personalInfo.name')"
        data-test-id="order-user-name"
        v-model:text="name"
      />
    </div>
    <div class="v-col-12 v-col-md-6 v-col-xl-3">
      <template
        v-if="appConfig.RestaurantSettingsPreRun.ForbiddenToChangePhoneNumber && accountStore.isLoggedIn"
      >
        <common-phone-input
          disabled
          required
          :label="translate('personalInfo.phoneNumber')"
          :validator-form="validatorForm"
          data-test-id="order-user-phone"
          v-model:phone="phone"
        >
          <template #right-end>
            <arora-button
              class-name="v-change-phone-button v-btn v-btn-link-secondary v-btn-text v-no-underline"
              ignore-settings
              :label="translate('personalInfo.phoneNumberChange')"
              @click="async () => await changePhonePopup()"
            />
          </template>
        </common-phone-input>
      </template>
      <common-phone-input
        v-else
        required
        :label="translate('personalInfo.phoneNumber')"
        :validator-form="validatorForm"
        data-test-id="order-user-phone"
        v-model:phone="phone"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.PersonalInfoEmailFieldHidden"
      class="v-col-12 v-col-md-6 v-col-xl-3"
    >
      <v-email-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: email,
          Required: appConfig.VueSettingsPreRun.PersonalInfoEmailFieldRequired,
          Validations: ['email', 'length'],
          MaxLength: 60
        }"
        :label="translate('personalInfo.email')"
        :required="appConfig.VueSettingsPreRun.PersonalInfoEmailFieldRequired"
        data-test-id="order-user-email"
        v-model:text="email"
      />
    </div>

    <div
      v-if="!appConfig.VueSettingsPreRun.PersonalInfoPersonCountHidden"
      class="v-col-12 v-col-md-6 v-col-xl-3"
    >
      <div class="v-user-count">
        <v-input
          v-if="personCount && parseInt(personCount) !== 0"
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: personCount,
            Required: appConfig.VueSettingsPreRun.PersonalInfoPersonCountRequired,
            Validations: ['digits', 'intMinMax'],
            MaxLength: appConfig.VueSettingsPreRun.PersonalInfoPersonCountMax,
            MinLength: 1
          }"
          :label="translate('personalInfo.personCount')"
          :required="appConfig.VueSettingsPreRun.PersonalInfoPersonCountRequired"
          v-model:text="personCount"
        />
        <arora-button
          v-if="parseInt(personCount) !== 0"
          class-name="user-count-btn minus v-increment-button"
          :disabled="parseInt(personCount) <= 1"
          label="–"
          @click="() => updatePersonCount(false)"
        >
          <icon-general-minus />
        </arora-button>
        <arora-button
          v-if="parseInt(personCount) !== 0"
          class-name="user-count-btn plus v-increment-button"
          label="+"
          @click="() => updatePersonCount(true)"
        >
          <icon-general-plus />
        </arora-button>
      </div>
      <template v-if="appConfig.VueSettingsPreRun.PersonalInfoShowNoCutleryOption">
        <div class="v-no-cutlery-option">
          <input
            id="v-no-cutlery"
            class="v-input-checkbox v-personal-info-checkbox"
            type="checkbox"
            v-model="personCountCheckbox"
          />

          <label
            class="for-form-control"
            for="v-no-cutlery"
          >
            <span class="v-checkbox" /><span v-html="translate('personalInfo.noCutlery')" />
          </label>
        </div>
      </template>
    </div>
  </div>
  <common-skeleton
    v-else
    :loaders-in-row="4"
  />
</template>

<script setup lang="ts">
import type { StateOrderDataPayload } from '~types/clientStore'

import { useCommon, useValidationStore, VEmailInput, vFormValidator, VInput } from '@arora/common'

import { getActivePinia } from 'pinia'

const { personCountDefaultValue = '1' } = defineProps<{
  personCountDefaultValue?: string
}>()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const phoneForLogin = useCookie('phoneForLogin')
const { clean, translate } = useI18nSanitized()
const appConfig = useAppConfig()
const { capitalLetter, debounce, stringIsNullOrWhitespace } = useCommon()

const validatorForm = 'cart'

const loaded = computed(() => clientStore.ClientState?.data !== null)

const nameLocal = ref<string | null>(null)

const name = computed<string>({
  get: () => {
    return nameLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Name ?? ''
  },
  set: (value) => {
    nameLocal.value = clean(capitalLetter(value ?? ''))
    debounce(() =>
      clientStore.updateOrderData({
        name: nameLocal.value ?? '',
        refreshState: true
      })
    )
  }
})

const phoneLocal = ref<string | null>(null)

const phone = computed<string>({
  get: () => {
    return phoneLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Phone ?? ''
  },
  set: (value) => {
    phoneLocal.value = clean(value)
    debounce(() =>
      clientStore.updateOrderData({
        phone: phoneLocal.value ?? '',
        refreshState: true
      })
    )
  }
})

const emailLocal = ref<string | null>(null)

const email = computed<string>({
  get: () => {
    return emailLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Email ?? ''
  },
  set: (value) => {
    emailLocal.value = clean(value)
    if (validationStore.validateSimple(value, 'email')) {
      debounce(() =>
        clientStore.updateOrderData({
          email: emailLocal.value ?? '',
          refreshState: true
        })
      )
    }
  }
})
const personCount = computed<string>({
  get: () => {
    return (
      clientStore.ClientState?.data?.StateOrderData?.PersonCount?.toString() ?? personCountDefaultValue
    )
  },
  set: (value) => {
    let valueFix = value?.replace(/\D/g, '') ?? ''
    if (valueFix === '' || Number.parseInt(valueFix) <= 0) {
      valueFix = '1'
    }

    debounce(() =>
      clientStore.updateOrderData({
        personCount: Number.parseInt(valueFix)
      })
    )
  }
})
const personCountCheckbox = computed<boolean>({
  get: () => {
    return personCount.value === '0'
  },
  set: (value) => {
    clientStore.updateOrderData({
      personCount: value ? 0 : Number.parseInt(personCountDefaultValue)
    })
  }
})

async function updatePersonCount(increment: boolean): Promise<void> {
  const personCountParsed: number = Number.parseInt(personCount.value?.replace(/\D/g, '') ?? '1')

  if (increment) {
    await clientStore.updateOrderData({
      personCount: personCountParsed + 1
    })
  } else if (personCountParsed > 0) {
    await clientStore.updateOrderData({
      personCount: personCountParsed - 1
    })
  }
}

async function changePhonePopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'confirmPopup',
    popupProperties: new Map<string, unknown>([
      [
        'message',
        `${translate('personalInfo.phoneNumberPopupText')}<br/><br/>${translate(
          'personalInfo.phoneNumberPopupTextAdd'
        )}`
      ],
      ['noText', translate('personalInfo.cancel')],
      ['type', 'warning'],
      [
        'yesFunction',
        async () => {
          phoneForLogin.value = accountStore.Profile.data?.Phone ?? null

          await accountStore.logout()
          await popupStore.openPopup({
            popupClosable: appConfig.VueSettingsPreRun.LoginShowCloseButton,
            popupName: 'accountManagementPopup'
          })
        }
      ],
      ['yesText', translate('personalInfo.phoneNumberPopupConfirm')]
    ])
  })
}

watch(
  () => loaded.value,
  (newState: boolean, oldState: boolean) => {
    if (newState && newState !== oldState) {
      const payload: StateOrderDataPayload = {}
      if (!clientStore.ClientState?.data?.StateOrderData?.PersonCount) {
        payload.personCount = Number(personCountDefaultValue)
      }

      if (accountStore.isLoggedIn) {
        if (stringIsNullOrWhitespace(name.value)) payload.name = accountStore.Profile?.data?.Name
        if (stringIsNullOrWhitespace(phone.value)) payload.phone = accountStore.Profile?.data?.Phone
        if (stringIsNullOrWhitespace(email.value)) payload.email = accountStore.Profile?.data?.Email
      }
      if (!clientStore.ClientState?.data?.StateOrderData?.PersonCount || accountStore.isLoggedIn) {
        clientStore.updateOrderData({
          ...payload
        })
      }
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-change-phone-button {
  font-size: 0.9rem;
}

.v-user-count {
  display: flex;

  margin-bottom: 0.5rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .v-arora-input {
    margin-bottom: 0;

    input {
      margin-bottom: 0;
    }
  }

  .v-btn {
    &:active,
    &:focus,
    &:visited {
      &.plus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.minus {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .v-increment-button {
    svg {
      min-width: 1rem;
      height: 1rem;
      path {
        stroke: variables.$BodyTextColor;
      }
    }
  }

  .user-count-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: variables.$FormBackground;
    color: variables.$BodyTextColor;
    flex: 0 0 40px;
    text-align: center;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      color: white;
    }

    &.minus {
      border-top-left-radius: variables.$BorderRadiusInput;
      border-bottom-left-radius: variables.$BorderRadiusInput;
      margin-left: 15px;

      &:hover {
        background: variables.$ErrorColor;
        color: variables.$ErrorForeColor;
      }
    }

    &.plus {
      border-top-right-radius: variables.$BorderRadiusInput;
      border-bottom-right-radius: variables.$BorderRadiusInput;
      border-left: 1px solid rgba(0, 0, 0, 0.05);

      &:hover {
        background: variables.$SuccessColor;
        color: variables.$SuccessForeColor;
      }
    }
  }
}

.v-no-cutlery-option {
  .v-checkbox {
    margin-right: 10px;
  }
}
</style>
