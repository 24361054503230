<i18n>
ru:
  carbs: Углеводы
  energy: Калорийность
  energyMeasure: ккал
  fat: Жиры
  quantity: Количество
  proteins: Белки
  weight: Вес
  weightTitle: Вес
  kbzyShort: КБЖУ
ua:
  carbs: Вуглеводи
  energy: Калорійність
  energyMeasure: ккал
  fat: Жиры
  proteins: Білки
  quantity: Кількість
  weight: Вага
  weightTitle: Вес
  kbzyShort: КБЖУ
us:
  carbs: Carbohydrates
  energy: Kcal
  energyMeasure: calories
  fat: fat
  proteins: Protein
  quantity: Quantity
  weight: Weight
  weightTitle: Weight
  kbzyShort: КБЖУ
</i18n>

<template>
  <ul
    v-if="
      (!hideWeight && caloricProduct.Weight > 0) ||
      caloricProduct.Quantity > 0 ||
      caloricProduct.EnergyAmount > 0 ||
      caloricProduct.ProteinsAmount > 0 ||
      caloricProduct.FatAmount > 0 ||
      caloricProduct.CarbohydrateAmount > 0
    "
    class="v-caloric-content"
    :class="{
      'v-caloric-content--one-line': inOneLine,
      'v-caloric-content--shorter-names': shorterNames
    }"
  >
    <li
      v-if="caloricProduct.Measure !== ProductMeasureType.Undefined && caloricProduct.Quantity > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate('caloric.quantity')"
      />
      <common-quantity-measure
        :measure="caloricProduct.Measure"
        :quantity="caloricProduct.Quantity"
      />
    </li>
    <li
      v-else-if="!hideWeight && caloricProduct.Weight > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate('caloric.weight')"
      />
      <span
        v-html="`${numeric(caloricProduct.Weight, 'nutritional')} ${translate('quantityMeasure.gram')}`"
      />
    </li>
    <li
      v-if="caloricProduct.EnergyAmount > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate(inOneLine ? 'caloric.energyMeasure' : 'caloric.energy')"
      />
      <span
        v-html="
          `${numeric(caloricProduct.EnergyAmount, 'nutritional')} ${
            !inOneLine ? translate('caloric.energyMeasure') : ''
          }`
        "
      />
    </li>
    <li
      v-if="caloricProduct.ProteinsAmount > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate('caloric.proteins')"
      />
      <span
        v-html="
          `${numeric(caloricProduct.ProteinsAmount, 'nutritional')} ${translate('quantityMeasure.gram')}`
        "
      />
    </li>
    <li
      v-if="caloricProduct.FatAmount > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate('caloric.fat')"
      />
      <span
        v-html="
          `${numeric(caloricProduct.FatAmount, 'nutritional')} ${translate('quantityMeasure.gram')}`
        "
      />
    </li>
    <li
      v-if="caloricProduct.CarbohydrateAmount > 0"
      class="v-caloric-column"
    >
      <span
        v-if="shorterNames"
        class="v-dot"
      />
      <span
        class="v-caloric-title v-mr-xxs"
        v-html="translate('caloric.carbs')"
      />
      <span
        v-html="
          `${numeric(caloricProduct.CarbohydrateAmount, 'nutritional')} ${translate('quantityMeasure.gram')}`
        "
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { HaveCaloricValues, Measurable } from '~types/common'

import { ProductMeasureType } from '~api/consts'

withDefaults(
  defineProps<{
    caloricProduct: HaveCaloricValues & Measurable
    shorterNames?: boolean
    hideWeight?: boolean
    inOneLine?: boolean
  }>(),
  {
    shorterNames: true
  }
)

const { numeric, translate } = useI18nSanitized()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-caloric-content {
  list-style: none;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.5rem;
  padding: 0 1rem;
  font-family: variables.$FontFamilyMain;

  li {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 500;
  }

  .v-caloric-column {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
    font-size: 0.85rem;
    align-items: baseline;
    padding: 4px;
    position: relative;
  }

  .v-dot {
    display: flex;
    width: 8px;
    height: 8px;
    background: variables.$PrimaryBackgroundColor;
    border-radius: 50%;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .v-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    opacity: 0.5;
  }

  &--shorter-names {
    .v-caloric-column {
      padding-left: 16px;
    }
  }

  &--one-line {
    margin: 20px 0 15px;
    padding: 6px;
    border-top: 1px solid variables.$BorderColor;
    border-bottom: 1px solid variables.$BorderColor;
    justify-content: space-between;

    .v-caloric-column {
      flex-direction: column;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
      padding: 0;
      font-size: 1.15rem;
      font-weight: 400;
      color: variables.$BodyTextColorLight;

      .v-dot {
        display: none;
      }

      .v-caloric-title {
        text-transform: capitalize;
      }
    }
  }
}

.v-kbzu-short {
  width: fit-content;
  font-size: 0.85rem;
  opacity: 0.4;
  border: variables.$BorderWidth solid;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
