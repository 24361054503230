<template>
  <slot />
</template>

<script setup lang="ts">
import { ErrorCode } from '~api/consts'

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const { translate } = useI18nSanitized()

watch(
  () => clientStore.ClientState.error,
  async (newValue, oldValue) => {
    if (newValue && newValue.code !== (oldValue?.code ?? 0)) {
      switch (newValue.code) {
        case ErrorCode.ThirdPartAPI:
          if (newValue.message) {
            await popupStore.showConfirm(
              {
                message: newValue.message,
                noFunction: () => {
                  reloadNuxtApp()
                },
                noText: translate('confirm.logoutTimeoutNo'),
                type: 'error',
                yesFunction: () => {
                  accountStore.logout()
                  reloadNuxtApp()
                },
                yesText: translate('confirm.logoutTimeoutYes')
              },
              false
            )
            break
          }
          break
        default:
          console.error('loadClientState failed', newValue)
      }
    }
  }
)
</script>
