<i18n>
ru:
  details: Подробнее
  title: "Статьи нашего ресторана"
ua:
  details: Детальніше
  title: "Статті нашого ресторану"
us:
  details: Details
  title: "Our Restaurant Articles"
</i18n>

<template>
  <div class="v-container">
    <div class="v-articles-list v-mb-xl">
      <h1
        class="v-w-100 v-mb-md"
        v-html="translate('articles.title')"
      />
      <common-cards-flex-mesh
        class-name="v-overflow-hidden"
        :items="articlesSorted"
        :max-items="appConfig.VueSettingsPreRun.ArticlesInRow"
        :mesh-gap="16"
      >
        <template #item="article: Article">
          <div class="v-h-100 v-w-100 v-d-flex v-article">
            <div
              v-if="haveImages"
              class="v-article--image"
            >
              <arora-nuxt-image
                v-if="imageExists(article.CoverImage)"
                :alt="article.Title"
                :image="article.CoverImage"
              />
              <icon-articles-placeholder v-else />
            </div>
            <div
              class="v-h-100 v-w-100 v-d-flex v-flex-column"
              :class="[haveImages ? 'v-article__have-images' : 'v-article__default']"
            >
              <div
                class="v-font-weight-700 v-mb-xs v-article--title"
                v-html="clean(article.Title)"
              />
              <div
                class="v-mb-sm v-article--teaser"
                v-html="sanitize(article.Teaser)"
              />
              <arora-nuxt-link
                class-name="v-link v-d-flex v-align-items-center v-article--details"
                :href="`${appConfig.VueSettingsPreRun.Links.ArticleSingleLink}/${article.HumanUrl}`"
                :label="sanitize(article.Title)"
              >
                <span
                  class="v-article--details-text v-font-weight-700"
                  v-html="translate('articles.details')"
                />
                <icon-general-arrow-next class="v-ml-xxs v-article--details-icon" />
              </arora-nuxt-link>
            </div>
          </div>
        </template>
      </common-cards-flex-mesh>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Article } from '~types/preloadRequests'

const appConfig = useAppConfig()
const { clean, sanitize, translate } = useI18nSanitized()
const { imageExists } = useImageInfo()

const articlesSorted = computed<Article[]>(() =>
  appConfig.SEOPreloadData.Articles.filter((a) => a.Active).sort((a, b) => {
    return a.Weight === undefined || a.Weight > b.Weight ? 1 : -1
  })
)

const haveImages = computed<boolean>(() => articlesSorted.value.some((a) => imageExists(a.CoverImage)))
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-articles-list {
  .v-article {
    &--image {
      height: 100%;
      width: 160px;

      background: variables.$BodyBackgroundDarken;
      color: variables.$BodyBackgroundDarker;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 70%;
        height: 70%;
      }

      @include mixins.sm {
        width: 140px;
      }
    }

    &__default {
      padding: 20px;
    }

    &__have-images {
      padding: 20px 20px 20px 12px;
      width: calc(100% - 160px);

      @include mixins.sm {
        width: calc(100% - 140px);
      }
    }

    &--title {
      font-size: 1.4rem;
    }

    &--teaser {
      font-size: 1.1rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: default;

      p {
        margin: 0;
      }
    }

    &--details {
      margin: auto 0 0;

      @include mixins.sm {
        margin: auto 0 0 auto;
      }
    }

    &--details-text {
      color: variables.$PrimaryBackgroundColor;
    }

    &--details-icon {
      width: 0.9rem;
      height: 0.9rem;
      color: variables.$PrimaryBackgroundColor;
    }
  }
}
</style>
