<template>
  <div
    v-if="appConfig.VueSettingsPreRun.MenuStickersShowOnMain && stickers.length > 0"
    class="v-stickers-wrapper"
  >
    <menu-filters-stickers-main-page-undeeps
      v-if="appConfig.VueSettingsPreRun.MenuMainStickersLayout === 'Undeeps'"
      :stickers="stickers"
    />
  </div>
</template>

<script setup lang="ts">
import type { StickerTag } from '~types/menuStore'

import { useCommon } from '@arora/common'

import { favoriteId } from '~api/consts'

const appConfig = useAppConfig()
const accountStore = useAccountStore()

const { translate } = useI18nSanitized()
const { dedupeArrayByID } = useCommon()

const stickers = ref<StickerTag[]>([])

onMounted(async () => {
  const stickerTags: StickerTag[] = []

  if (accountStore.isLoggedIn) {
    stickerTags.push({
      Color: '',
      ID: favoriteId,
      ImageUrl: '',
      Title: translate('filtersPage.favoritesSticker')
    })
  }

  stickers.value = dedupeArrayByID<StickerTag>(stickerTags)
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-stickers-wrapper {
  border-radius: variables.$BorderRadius;
  padding-bottom: 10px;
}
</style>
