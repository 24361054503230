<template>
  <div class="v-container">
    <div class="v-cart">
      <div class="v-row">
        <div class="v-col-12">
          <h1
            class="v-title"
            v-html="translate('stage.cart')"
          />
        </div>
        <div class="v-col-12">
          <lazy-cart-old-messages />
        </div>
        <div class="v-col-12">
          <cart-old-extra-products />
        </div>
        <div class="v-col-12">
          <div
            class="v-cart-one-stage-personal-info-title"
            v-html="translate('stage.personalInfo')"
          />
          <cart-old-personal-info />
        </div>

        <div class="v-col-12 v-col-sm-6">
          <cart-old-delivery-time-page />
        </div>
        <div
          v-if="clientStore.courierDelivery"
          class="v-col-12 v-col-sm-6"
        >
          <cart-old-street-list-autocomplete />
          <cart-old-additional-info />
        </div>
        <div
          v-else
          class="v-col-12 v-col-sm-6"
        >
          <cart-old-comment />
        </div>

        <div class="v-col-12 v-col-sm-8">
          <cart-old-total-summary-page />
        </div>

        <div class="v-col-12 v-col-sm-4">
          <cart-old-pay-method-page />
          <cart-old-callback />
          <cart-old-service-fee />
          <cart-old-priority-service />
          <cart-old-second-step-button />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()
const clientStore = useClientStore()
</script>
