<i18n>
ru:
  button: Открыть в приложении банка
  totalPay: 'Итого к оплате:'
  howToStart: Наведите камеру телефона на QR-код.
  howToStartMobile: Нажмите на кнопку Открыть&nbsp;в&nbsp;приложении банка.
  howToSelectApp: Выберите приложение банка&nbsp;и&nbsp;перейдите к оплате.
  howToSelectInApp: Завершите оплату. Сумма спишется&nbsp;с&nbsp;вашего счета, заведение сразу получит платеж.
  howToAfter: Вы также можете отсканировать QR-код сразу из приложения банка.
  howToTitle: Как платить через СБП
  overQr: Сканируйте QR-код для оплаты
  timerText: 'Осталось: {timer}'
ua:
  button: Платити
  totalPay: 'Загалом до сплати:'
  howToStart: Як платити
  howToMobile: Як платити
  howToTitle: Як платити
  overQr: Скануйте QR-код для оплати
  timerText: 'Залишилося: {timer}'
us:
  button: Pay
  totalPay: 'Total:'
  howToStart: How to pay
  howToMobile: How to pay
  howToTitle: How to pay
  overQr: Scan the QR code to pay
  timerText: 'Time remaining: {timer}'
</i18n>

<template>
  <div
    class="v-popup-head v-title popup-qr-head"
    v-html="translate('qrPayPopup.totalPay')"
  />
  <div class="popup-qr-subhead v-mb-sm">
    <common-currency :amount="totalSum" />
  </div>
  <div
    v-if="!isSmall"
    class="popup-qr-overbody v-text-center"
    v-html="translate('qrPayPopup.overQr')"
  />
  <div
    v-if="isSmall"
    class="popup-qr-body v-mb-sm"
  >
    <arora-nuxt-link
      v-if="link"
      class-name="v-btn v-btn-default v-btn-primary v-btn-lg"
      open-in-new-tab
      :href="link"
      :label="translate('qrPayPopup.button')"
    />
    <arora-loader v-else />
  </div>
  <div
    v-else
    class="popup-qr-body v-mb-sm"
  >
    <arora-nuxt-link
      v-if="link && imgBase"
      class-name="popup-qr-border"
      :href="link"
      :label="translate('qrPayPopup.overQr')"
    >
      <img
        class="v-img-fluid"
        :alt="translate('qrPayPopup.overQr')"
        :src="imgBase"
      />
    </arora-nuxt-link>
    <arora-loader v-else />
  </div>
  <div
    class="popup-qr-how-to"
    v-html="translate('qrPayPopup.howToTitle')"
  />
  <div class="popup-qr-how-to-text v-mb-sm">
    <ol>
      <li v-html="translate(isSmall ? 'qrPayPopup.howToStartMobile' : 'qrPayPopup.howToStart')" />
      <li v-html="translate('qrPayPopup.howToSelectApp')" />
      <li v-html="translate('qrPayPopup.howToSelectInApp')" />
    </ol>
    <span
      v-if="!isSmall"
      v-html="translate('qrPayPopup.howToAfter')"
    />
  </div>
  <div
    v-if="timerDiff"
    class="popup-qr-subbody"
    role="timer"
    v-html="translate('qrPayPopup.timerText', { timer: timerDiff.toFormat('mm:ss') })"
  />
</template>

<script setup lang="ts">
import type { OrderInfoStatus } from '~types/clientStore'

import { type GUID, useWindowSize } from '@arora/common'

import { DateTime, type Duration } from 'luxon'
import generalAPI from '~api/general'

const { orderId } = defineProps<{
  orderId: GUID
  totalSum: number
}>()

const clientStore = useClientStore()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const { isSmall } = useWindowSize()

const link = ref<string>('')
const timerDiff = ref<Duration | null>(null)

let timer: ReturnType<typeof setTimeout>
let timerCheck: ReturnType<typeof setTimeout>
let timerEnd: DateTime

onUnmounted(() => {
  clearInterval(timer)
  clearInterval(timerCheck)
})

const imgBase = ref<string | null>(null)

clientStore.loadOrderProcessing(orderId).then((processed) => {
  if (!processed || !processed.SBPResult || !processed.Success || processed.Error > 0) {
    navigateTo(`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${orderId}`)

    return
  }

  link.value = processed.SBPResult.PayUrl
  timerEnd = DateTime.now().plus({ milliseconds: processed.SBPResult.TimeLimitMS })

  timer = setInterval(timerTick, 1000)
  timerCheck = setInterval(timerCheckTick, processed.SBPResult.CheckStatusDelayMS)

  generalAPI.generateQR(processed.SBPResult.PayUrl).then((qr) => {
    imgBase.value = `data:${qr.format};base64, ${qr.payload64}`
  })
})

function timerCheckTick(): void {
  clientStore
    .refreshOrderStatus(orderId)
    .then((newStatus: OrderInfoStatus) => {
      if (newStatus.Status > 15)
        navigateTo(`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${orderId}`)
    })
    .catch((error) => {
      console.error('store request error', error)
    })
}

function timerTick(): void {
  timerDiff.value = timerEnd.diffNow(['minutes', 'seconds'])

  if (timerDiff.value.minutes <= 0 && timerDiff.value.seconds <= 0) {
    clearTimeout(timer)
    navigateTo(`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${orderId}`)
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.popup-qr-head {
  color: variables.$BodyTextColor;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.popup-qr-subhead {
  color: variables.$BodyTextColor;
  font-size: 24px;
  margin-bottom: 5px;
}

.popup-qr-overbody {
  font-size: 16px;
  margin-bottom: 5px;
  color: variables.$BodyTextColorLight;
  pointer-events: none;
}

.popup-qr-body {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0 100px;

  .v-btn {
    padding: 15px;
  }

  @include mixins.md {
    padding: 0 55px;
  }

  @include mixins.sm {
    padding: 5px;
    font-size: 16px;
  }
}

.popup-qr-how-to {
  font-size: 16px;
  color: variables.$BodyTextColor;
  margin-bottom: 5px;
}

.popup-qr-how-to-text {
  font-size: variables.$TextSizeMain;
  color: variables.$BodyTextColor;
  margin-bottom: 1.1rem;

  li {
    padding: 4px 0;
  }
}

.popup-qr-subbody {
  font-size: variables.$TextSizeMain;
  color: variables.$BodyTextColorLight;
  text-align: right;
  pointer-events: none;
}

.popup-qr-border {
  background: #fff;
  padding: 5px;
  border-radius: variables.$BorderRadius;
  cursor: pointer;
}
</style>
