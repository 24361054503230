<template>
  <div class="v-delivery-time-buttons-popup">
    <div
      class="v-popup-head v-title v-delivery-time-buttons-title"
      v-html="title"
    />

    <div class="v-pm-shadow">
      <arora-swiper-slider
        show-part-of-next-slide
        :items="dateButtons"
        max-items="auto"
      >
        <template #item="item: dateButton">
          <div
            class="v-delivery-date-single"
            :class="{ 'v-delivery-date-single--selected': (item.value?.Date ?? 0) === selectedDate }"
            data-test-id="delivery-time-popup-dates"
            @click="() => onClickDate(item.value)"
          >
            {{ item.text }}
          </div>
        </template>
      </arora-swiper-slider>
    </div>
    <div class="v-delivery-time-buttons-wrapper v-pm-shadow">
      <div
        v-if="clientStore.ClientState.data?.StateOrderData?.DeliverRightNow"
        v-html="translate('deliveryTimePage.noTimeNeeded')"
      />
      <common-cards-flex-mesh
        v-else
        :items="timeButtons"
        :max-items="minutesSettingType === MinutesSettingType.Range ? 2 : 3"
        :min-items="minutesSettingType === MinutesSettingType.Range ? 1 : 2"
        data-test-id="delivery-time-popup-mesh"
      >
        <template #item="item: timeButton">
          <div
            class="v-delivery-time-single"
            :class="{ 'v-delivery-time-single--selected': item.value === clientTime }"
            @click="() => onTimeClick(item.value)"
          >
            {{ item.text }}
          </div>
        </template>
      </common-cards-flex-mesh>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LimitOrderDate, TerminalDeliveryMinute, TerminalMinutesSetting } from '~types/clientStore'

import { MinutesSettingType } from '~api/consts'

type timeButton = {
  text: string
  value: number
}

type dateButton = {
  text: string
  value: LimitOrderDate | null
}

const { enabledASAP, enabledScheduled, title } = defineProps<{
  title: string
  enabledASAP: boolean
  enabledScheduled: boolean
}>()

const clientStore = useClientStore()
const popupStore = usePopupStore()
const { eventOn } = useEmitter()
const { fromMillisInZone, isDateToday } = useDateTime()

const { dateTime, translate } = useI18nSanitized()
const appConfig = useAppConfig()

const scheduleDates = computed<LimitOrderDate[]>(() =>
  Object.values(clientStore?.TimeRestrictions.data?.Scheduled?.Schedule?.Dates ?? {})
)

const minutesSetting = computed<TerminalMinutesSetting | undefined>(() => {
  return clientStore?.TimeRestrictions.data?.Scheduled?.MinutesSetting
})

const minutesSettingType = computed<MinutesSettingType>(() =>
  (minutesSetting.value?.DeliveryMinutes ?? []).length === 0
    ? MinutesSettingType.Default
    : (minutesSetting.value?.MinutesSettingType ?? MinutesSettingType.Default)
)

const dateButtons = ref<dateButton[]>([])
const timeButtons = ref<timeButton[]>([])
const selectedDate = ref<number>(0)
const clientTime = ref<number>(0)

function onClickDate(date: LimitOrderDate | null): void {
  if (date) {
    selectedDate.value = date.Date
    clientStore.updateOrderData({
      deliverRightNow: false,
      refreshState: true
    })
  } else {
    selectedDate.value = 0
    clientStore
      .updateOrderData({
        deliverRightNow: true,
        refreshState: true
      })
      .then(() => {
        popupStore.closePopup()
      })
  }

  makeButtons()
}

function onTimeClick(value: number): void {
  clientStore
    .updateOrderData({
      deliverRightNow: false,
      deliveryTime: Math.floor(value / 1000),
      refreshState: true
    })
    .then(() => {
      popupStore.closePopup()
    })
}

onMounted(() => {
  const userTime = (clientStore.ClientState.data?.StateOrderData?.DeliveryTime ?? 0) * 1000

  if (userTime !== 0) {
    let limitOrderDate = 0
    let index = -1

    do {
      index++
      limitOrderDate = scheduleDates.value[index].Date
    } while (limitOrderDate > userTime)

    if (!clientStore.ClientState.data?.StateOrderData?.DeliverRightNow) {
      selectedDate.value = limitOrderDate
      clientTime.value = userTime
    }
  }

  makeButtons()

  eventOn('v-time-refresh', () => {
    makeButtons()
  })
})

function makeButtons(): void {
  const dateButtonsLocal = [] as dateButton[]

  if (enabledASAP) {
    dateButtonsLocal.push({
      text: translate('deliveryTimeRathloriel.deliveryASAPLabel'),
      value: null
    })
  }
  timeButtons.value = []

  if (enabledScheduled) {
    for (const limitOrderDate of scheduleDates.value) {
      //for each date
      const isCurrentDate = limitOrderDate.Date === selectedDate.value

      dateButtonsLocal.push({
        text: isDateToday(limitOrderDate.Date, appConfig.RestaurantSettingsPreRun.GMT)
          ? translate('deliveryTimePage.today')
          : dateTime(
              limitOrderDate.Date + appConfig.RestaurantSettingsPreRun.GMT * 60 * 60 * 1000,
              'date'
            ),
        value: limitOrderDate
      })

      if (isCurrentDate) {
        makeTimeButtons(limitOrderDate)
      }
    }
  }

  dateButtons.value = dateButtonsLocal
}

function makeTimeButtons(limitOrderDate: LimitOrderDate): void {
  const timeButtonsLocal = [] as timeButton[]

  const minutes: TerminalDeliveryMinute[] = minutesSetting.value?.DeliveryMinutes ?? []

  for (const period of limitOrderDate.RestrictedPeriods) {
    const periodStart = fromMillisInZone(period.Start, appConfig.RestaurantSettingsPreRun.GMT)
    const periodEnd = fromMillisInZone(period.End, appConfig.RestaurantSettingsPreRun.GMT)
    let runner = fromMillisInZone(
      Math.floor(period.Start / 1000) * 1000,
      appConfig.RestaurantSettingsPreRun.GMT
    ).set({
      second: 0
    })

    switch (minutesSettingType.value) {
      case MinutesSettingType.Value:
      case MinutesSettingType.Range: {
        while (runner < periodEnd) {
          for (const minuteSetting of minutes) {
            runner = runner.set({ minute: minuteSetting.PeriodStart })

            if (runner > periodStart) {
              const ms = runner.toMillis()

              let text = ''
              const startMinute =
                minuteSetting.PeriodStart < 10
                  ? `0${minuteSetting.PeriodStart}`
                  : minuteSetting.PeriodStart

              const periodEndMinutes = minuteSetting.PeriodEnd ?? minuteSetting.PeriodStart

              text =
                minutesSettingType.value === MinutesSettingType.Range
                  ? translate('deliveryTimeRathDinenPopup.minutesPeriodText', {
                      end: periodEndMinutes < 10 ? `0${periodEndMinutes}` : periodEndMinutes,
                      hour: runner.hour,
                      hourEnd:
                        minuteSetting.PeriodStart > periodEndMinutes ? runner.hour + 1 : runner.hour,
                      start: startMinute
                    })
                  : `${runner.hour}:${startMinute}`

              if (periodEnd.hour !== runner.hour || periodEnd.minute >= periodEndMinutes) {
                timeButtonsLocal.push({
                  text: text,
                  value: ms
                })
              }
            }
          }
          runner = runner.set({ minute: 0 }).plus({ hour: 1 })
        }

        break
      }
      case MinutesSettingType.Default: {
        // add minutes to start from minutes that evenly divisible by minutesStep
        // it could be a problem in case if period is smaller than minutesStep * 2 (don't do it)
        const minutesStepRemainder = runner.minute % clientStore.DeliveryTime.minutesStep
        const minutesToAddToFit =
          minutesStepRemainder === 0 ? 0 : clientStore.DeliveryTime.minutesStep - minutesStepRemainder
        runner = runner.plus({ minutes: minutesToAddToFit }).startOf('minute')

        while (runner < periodEnd) {
          const ms = runner.toMillis()
          timeButtonsLocal.push({
            text: dateTime(ms, 'time'),
            value: ms
          })
          runner = runner.plus({ minutes: clientStore.DeliveryTime.minutesStep })
        }
        break
      }
    }
  }

  timeButtons.value = timeButtonsLocal
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-delivery-time-buttons-popup {
  .v-swiper .swiper-wrapper {
    .swiper-slide {
      width: fit-content;
    }
  }
}

.v-delivery-date-single {
  padding: 14px;
  cursor: pointer;
  font-size: variables.$TextSizeMain;
  font-variant-numeric: tabular-nums;
  border-radius: variables.$BorderRadiusInput;
  background: variables.$FormBackground;
  box-shadow: variables.$InputShadow;

  &--selected {
    border: 1px solid variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryBackgroundColor;
    border-radius: variables.$BorderRadiusInput;
  }
}

.v-delivery-time-single {
  padding: 14px;
  cursor: pointer;
  font-size: variables.$TextSizeMain;
  font-variant-numeric: tabular-nums;
  border-radius: variables.$BorderRadiusInput;
  background: variables.$FormBackground;
  box-shadow: variables.$InputShadow;

  &--selected {
    border: 1px solid variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryBackgroundColor;
    border-radius: variables.$BorderRadiusInput;
  }
}
</style>
