<template>
  <arora-loader v-if="!imageLoadingFailed && !imageLoadingSucceeded" />
  <div
    v-else-if="imageLoadingFailed"
    class="v-broken-image"
  >
    <icon-general-broken-img />
  </div>
  <img
    v-if="isSmall || !appConfig.VueSettingsPreRun.MenuPageImageZoom"
    class="v-img-fluid"
    :alt="alt"
    :loading="disableLazy ? 'eager' : 'lazy'"
    ref="img"
    v-show="!imageLoadingFailed"
  />
  <div
    v-else
    class="v-zoomable"
    :style="
      cursorOverZoomable
        ? `background-image: url(${image?.Domain ?? ''}${image?.Path})`
        : 'background-image: none'
    "
    :onmousemove="zoom"
    ref="zoomable"
    v-show="!imageLoadingFailed"
    @blur="() => (cursorOverZoomable = false)"
    @focusin="() => (cursorOverZoomable = true)"
    @mouseleave="() => (cursorOverZoomable = false)"
    @mouseover="() => (cursorOverZoomable = true)"
  >
    <img
      class="v-img-fluid"
      :alt="alt"
      :loading="disableLazy ? 'eager' : 'lazy'"
      ref="img"
      v-show="!imageLoadingFailed"
    />
  </div>
</template>

<script setup lang="ts">
import type { AroraImageCommon } from '~types/props'

import { useWindowSize } from '@arora/common'

const { image } = defineProps<AroraImageCommon>()

const { isSmall } = useWindowSize()
const imageLoadingSucceeded = ref<boolean>(false)
const imageLoadingFailed = ref<boolean>(false)

const zoomable = ref<HTMLElement | null>(null)
const cursorOverZoomable = ref<boolean>(false)

const appConfig = useAppConfig()

function zoom(error: MouseEvent): void {
  if (appConfig.VueSettingsPreRun.MenuPageImageZoom && zoomable.value) {
    const x = (error.offsetX / zoomable.value.offsetWidth) * 100
    const y = (error.offsetY / zoomable.value.offsetHeight) * 100

    zoomable.value.style.backgroundPosition = `${x}% ${y}%`
  }
}

const img = ref<HTMLImageElement | undefined>()

onMounted(() => {
  if (!image) {
    imageLoadingFailed.value = true

    return
  }

  const newImg = new Image()
  newImg.addEventListener('load', () => {
    if (!img.value) return

    img.value.src = newImg.src
    imageLoadingSucceeded.value = true
  })
  newImg.onerror = () => (imageLoadingFailed.value = true)
  const format = appConfig.VueSettingsPreRun.ImageDisableProgressiveFormats ? '' : '?webp'
  newImg.src = (image.Domain ?? '') + image.Path + format
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-zoomable {
  background-position: 50% 50%;
  border-radius: variables.$BorderRadius;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: zoom-in;

  img {
    display: block;
    width: 100%;
    border-radius: variables.$BorderRadius;

    &:hover {
      opacity: 0;
    }
  }
}
</style>
