<i18n>
ru:
  noTimeNeeded: При выборе доставки «как можно скорее» выбор времени не требуется
  today: Сегодня
ua:
  noTimeNeeded: При виборі доставки «як можно швидше» вибір часу не потрібний
  today: Сьогодні
us:
  noTimeNeeded: When choosing delivery «as soon as possible» no time selection required
  today: Today
</i18n>

<template>
  <template v-if="loaded">
    <div class="time-restriction-messages mt-2 v-mb-xs">
      <div
        v-for="message in uniqueMessages"
        :key="sanitize(message.Text)"
        v-html="sanitize(message.Text)"
      />
    </div>

    <cart-old-delivery-time-rath-celerdain
      v-if="appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'RathCelerdain'"
      :delivery-time-by-settings="deliveryTimeBySettings"
    />
    <cart-old-delivery-time-rath-dinen
      v-else-if="appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'RathDinen'"
      :delivery-time-by-settings="deliveryTimeBySettings"
    />
    <cart-old-delivery-time-rathloriel
      v-else-if="appConfig.VueSettingsPreRun.DeliveryTimeLayout === 'Rathloriel'"
    />

    <transition
      v-if="appConfig.VueSettingsPreRun.AverageTimeDisplayInCart"
      appear
      mode="out-in"
      name="slidedown"
    >
      <div
        v-if="
          clientStore.averageTimeWithDelayMs > 0 &&
          clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow
        "
        class="v-average-time-block"
      >
        <div class="v-average-time-block__cart">
          <span
            class="v-mr-xs"
            v-html="translate('deliveryTimeRathloriel.averageTimeWarning')"
          />
          <header-parts-average-time-base is-address-selector-popup-time />
        </div>
      </div>
    </transition>
  </template>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { DateTime } from 'luxon'
import type {
  LimitOrderDate,
  LimitOrderPeriod,
  LimitOrderPeriodMessages,
  TerminalDeliveryMinute
} from '~types/clientStore'

import { MinutesSettingType, OrderType } from '~api/consts'

const appConfig = useAppConfig()
const restaurantStore = useRestaurantStore()
const clientStore = useClientStore()
const { dateTime, sanitize, translate } = useI18nSanitized()
const { eventEmit } = useEmitter()
const { fromMillisInZone } = useDateTime()

const updateRestrictionsIntervalId = ref<ReturnType<typeof setTimeout> | null>(null)

const loaded = computed(
  () => restaurantStore.TimeCorrection?.data !== null && clientStore.ClientState?.data !== null
)

const restrictions = computed<LimitOrderPeriod | null>(() => clientStore?.TimeRestrictions.data)

onMounted(async () => {
  if (import.meta.client) {
    await clientStore.initClientState()
    await restaurantStore.loadTimeCorrection()
  }
})

onUnmounted(() => {
  if (updateRestrictionsIntervalId.value) {
    clearTimeout(updateRestrictionsIntervalId.value)
    updateRestrictionsIntervalId.value = null
  }
})

function deliveryTimeBySettings(dt: DateTime): string {
  let minutesSettingType =
    clientStore.TimeRestrictions.data?.Scheduled?.MinutesSetting?.MinutesSettingType ??
    MinutesSettingType.Default
  const minutes: TerminalDeliveryMinute[] =
    clientStore.TimeRestrictions.data?.Scheduled?.MinutesSetting?.DeliveryMinutes ?? []
  if (minutes.length === 0) {
    minutesSettingType = MinutesSettingType.Default
  }

  switch (minutesSettingType) {
    case MinutesSettingType.Value:
      for (const minuteSetting of minutes) {
        if (minuteSetting.PeriodStart === dt.minute) {
          const periodStart = minuteSetting.PeriodStart <= 0 ? '00' : minuteSetting.PeriodStart

          return `${dateTime(dt.toMillis(), 'date')} ${dt.hour}:${periodStart}`
        }
      }
      break
    case MinutesSettingType.Range:
      for (const minuteSetting of minutes) {
        if (minuteSetting.PeriodStart === dt.minute) {
          return `${dateTime(dt.toMillis(), 'date')} ${translate(
            'deliveryTimeRathDinenPopup.minutesPeriodText',
            {
              end:
                !minuteSetting.PeriodEnd || minuteSetting.PeriodEnd <= 0
                  ? '00'
                  : minuteSetting.PeriodEnd,
              hour: dt.hour,
              hourEnd:
                minuteSetting.PeriodStart > (minuteSetting.PeriodEnd ?? 0) ? dt.hour + 1 : dt.hour,
              start: minuteSetting.PeriodStart <= 0 ? '00' : minuteSetting.PeriodStart
            }
          )}`
        }
      }
      break
    case MinutesSettingType.Default:
      return dateTime(dt.toMillis(), 'orderTime')
  }

  return ''
}

function getSelectedDate(restrictions: LimitOrderPeriod | null, day: number): LimitOrderDate | null {
  let selectedDate: LimitOrderDate | null = null

  if (!restrictions || restrictions?.Scheduled === null) {
    return selectedDate
  }

  for (const dateKey in restrictions.Scheduled.Schedule.Dates) {
    const availableDate = restrictions.Scheduled.Schedule.Dates[dateKey]

    if (availableDate.Date === day) {
      selectedDate = availableDate
    }
  }

  return selectedDate
}

const selectedDay = computed<LimitOrderDate | null>(() => {
  const time: number | undefined = clientStore.ClientState?.data?.StateOrderData?.DeliveryTime
  if (time && appConfig.RestaurantSettingsPreRun.GMT !== null) {
    const dt = fromMillisInZone(time * 1000, appConfig.RestaurantSettingsPreRun.GMT)

    const day = dt.startOf('day').toMillis()

    return getSelectedDate(restrictions.value, day)
  }

  return null
})
/**
 * Returns unique messages to show to a user
 */
const uniqueMessages = computed<LimitOrderPeriodMessages[]>(() => {
  const messages = [] as LimitOrderPeriodMessages[]

  // if there are messages for ASAP
  if (restrictions.value !== null && restrictions.value.ASAP !== null) {
    for (const message of restrictions.value.ASAP.Messages) {
      // expect only a few messages so iterate over to check for duplicates
      // (it's faster than building an object to get only unique texts)
      const duplicateFound = messages.some((value) => value.Text === message.Text)

      if (!duplicateFound) {
        messages.push(message)
      }
    }
  }

  // if there are messages for schedule
  if (
    restrictions.value &&
    restrictions.value.Scheduled !== null &&
    restrictions.value.Scheduled.EnabledByTerminalSettingsMessage !== null
  ) {
    const message = { Text: restrictions.value.Scheduled.EnabledByTerminalSettingsMessage ?? '' }
    const duplicateFound = messages.some((value) => value.Text === message.Text)

    if (!duplicateFound) {
      messages.push(message)
    }
  }

  // if day is selected and there are messages for it
  if (selectedDay.value !== null && selectedDay.value.Messages !== null) {
    for (const message of selectedDay.value.Messages) {
      // expect only a few messages so iterate over to check for duplicates
      // (it's faster than building an object to get only unique texts)
      const duplicateFound = messages.some((value) => value.Text === message.Text)

      if (!duplicateFound) {
        messages.push(message)
      }
    }
  }

  return messages
})

async function reloadTime(): Promise<void> {
  await clientStore.reloadTimeRestrictions()

  eventEmit('v-time-refresh')

  if (restrictions.value?.ASAP)
    if (!restrictions.value.ASAP.AvailableNow || !restrictions.value.ASAP.EnabledByTerminalSettings) {
      await clientStore.updateOrderData({
        deliverRightNow: false,
        refreshState: true
      })
    } else if (
      clientStore.ClientState?.data?.StateOrderData &&
      !clientStore.ClientState.data.StateOrderData.DeliverRightNow &&
      clientStore.ClientState?.data?.StateOrderData.DeliveryTime <= 0
    ) {
      await clientStore.updateOrderData({
        deliverRightNow: true,
        refreshState: true
      })
    }
}

watch(
  () => loaded.value,
  (newState: boolean) => {
    if (newState) {
      reloadTime()
    }
  }
)

watch(
  () => clientStore.selectedTerminalId,
  (newValue: string | undefined, oldValue: string | undefined) => {
    if (oldValue !== newValue) {
      reloadTime()
    }
  }
)

watch(
  () => clientStore.ClientState.data?.OrderType ?? OrderType.Default,
  (newValue: number, oldValue: number) => {
    if (oldValue !== newValue) {
      reloadTime()
    }
  }
)

watch(
  () => clientStore?.TimeRestrictions?.minUpdatePeriod ?? 0,
  (value: number) => {
    if (value > 0) {
      // update time restrictions periodically to avoid orders in the past
      const updInterval = clientStore.TimeRestrictions.minUpdatePeriod
      updateRestrictionsIntervalId.value = setInterval(
        () => {
          reloadTime()
        },
        updInterval * 60 * 1000
      )
    }
  }
)
</script>

<style lang="scss">
.v-average-time-block__cart {
  padding: 1rem;
}
</style>
