<i18n>
ru:
  offer: Публичная оферта
  paymentRules: Условия оплаты
  personalData: Политика обработки персональных данных
  pointsPage: Условия бонусной системы
ua:
  offer: Публічна оферта
  paymentRules: Умови оплати
  personalData: Політика обробки персональних даних
  pointsPage: Умови бонусної системи
us:
  offer: Public offer
  paymentRules: Payment terms
  personalData: Personal data processing policy
  pointsPage: Bonus system terms and conditions
</i18n>

<template>
  <div class="v-legal-page">
    <div class="v-mb-sm">
      <arora-chips
        with-links
        :chips="tabSettingsArray"
        v-model:selected="tabSelectedId"
      />
    </div>
    <page-legal-offer
      v-if="tabSelectedId === 0 && appConfig.StaticTexts.offer"
      :content="appConfig.StaticTexts.offer.content ?? ''"
      :title="appConfig.StaticTexts.offer.title ?? ''"
    />
    <page-legal-payment-rules
      v-else-if="tabSelectedId === 1 && appConfig.StaticTexts.paymentRules"
      :content="appConfig.StaticTexts.paymentRules.content ?? ''"
      :requisites="appConfig.StaticTexts.requisites ?? ([] as LegalInfoRequisite[])"
      :title="appConfig.StaticTexts.paymentRules.title ?? ''"
    />
    <page-legal-personal-data
      v-else-if="tabSelectedId === 2 && appConfig.StaticTexts.personalData"
      :content="appConfig.StaticTexts.personalData.content ?? ''"
      :title="appConfig.StaticTexts.personalData.title ?? ''"
    />
    <page-legal-points
      v-else-if="tabSelectedId === 3 && appConfig.StaticTexts.pointsPage"
      :content="appConfig.StaticTexts.pointsPage.content ?? ''"
      :title="appConfig.StaticTexts.pointsPage.title ?? ''"
    />
    <template
      v-else
      v-for="[index, additionalDocument] in appConfig.StaticTexts.additional.entries()"
    >
      <div
        v-if="tabSelectedId === index + 3"
        :key="index"
        class="v-pb-xs"
        v-html="additionalDocument.content"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TabsSettings } from '~types/common'
import type { LegalInfoRequisite } from '~types/preloadRequests'

const { activeTab } = defineProps<{
  activeTab: number
}>()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const tabSelectedId = ref<number | undefined>(undefined)
const tabSettingsArray = ref<TabsSettings<number>[]>([] as TabsSettings<number>[])
tabSelectedId.value = activeTab

onBeforeMount(async () => {
  if (appConfig.StaticTexts.offer && appConfig.StaticTexts.offer.visibility)
    tabSettingsArray.value.push({
      ID: 0,
      Link: appConfig.VueSettingsPreRun.Links.AgreementLink,
      Name: appConfig.StaticTexts.offer.title ?? translate('legalInfo.offer')
    })

  if (appConfig.StaticTexts.paymentRules && appConfig.StaticTexts.paymentRules.visibility)
    tabSettingsArray.value.push({
      ID: 1,
      Link: appConfig.VueSettingsPreRun.Links.PayRulesLink,
      Name: appConfig.StaticTexts.paymentRules.title ?? translate('legalInfo.paymentRules')
    })

  if (appConfig.StaticTexts.personalData && appConfig.StaticTexts.personalData.visibility)
    tabSettingsArray.value.push({
      ID: 2,
      Link: appConfig.VueSettingsPreRun.Links.PersonalDataLink,
      Name: appConfig.StaticTexts.personalData.title ?? translate('legalInfo.personalData')
    })

  if (appConfig.StaticTexts.pointsPage && appConfig.StaticTexts.pointsPage.visibility)
    tabSettingsArray.value.push({
      ID: 3,
      Link: appConfig.VueSettingsPreRun.Links.PointsLink,
      Name: appConfig.StaticTexts.pointsPage.title ?? translate('legalInfo.pointsPage')
    })

  if (appConfig.StaticTexts.additional && appConfig.StaticTexts.additional.length > 0) {
    for (const item of appConfig.StaticTexts.additional) {
      tabSettingsArray.value.push({
        ID: tabSettingsArray.value.length,
        Link: item.link,
        Name: item.title
      })
    }
  }
})
</script>

<style lang="scss">
.v-legal-page {
  h1 {
    font-size: 1.6rem;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.3rem;
  }

  ul {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }

  ol {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }
}
</style>
