<template>
  <div class="v-container">
    <div class="v-col-12">
      <lazy-cart-old-messages />
    </div>
    <div class="v-col-12">
      <cart-old-extra-products />
    </div>
  </div>
  <cart-old-recommended />
  <div class="v-container">
    <div class="v-cart">
      <div class="v-col-12">
        <cart-old-gifts-ranged />
      </div>
      <div class="v-col-12">
        <cart-old-birthday-gift-select />
      </div>
      <div class="v-col-12">
        <cart-old-promocode />
      </div>
      <div class="v-col-12 v-text-right">
        <cart-old-cleanup-button />
      </div>
      <div class="v-col-12">
        <lazy-cart-old-items-page />
      </div>
      <div class="v-d-flex v-flex-row">
        <div class="v-col-12 v-col-md-6 v-col-lg-7 v-col-xl-8 v-px-xs">
          <lazy-cart-old-total-summary-page />
        </div>
        <div class="v-col-12 v-col-md-6 v-col-lg-5 v-col-xl-4 v-px-xs">
          <lazy-cart-old-order-type />

          <template v-if="clientStore.courierDelivery">
            <lazy-account-saved-addresses-page :can-redact-address="false" />
            <lazy-address-delivery-city-district />
          </template>
          <template v-else-if="clientStore.selfService">
            <lazy-cart-old-self-service-terminals />
          </template>
          <template v-else-if="clientStore.inHall">
            <lazy-cart-old-in-hall-table-select />
          </template>

          <lazy-cart-old-callback />
          <lazy-cart-old-second-step-button />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const clientStore = useClientStore()
</script>
