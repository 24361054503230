<template>
  <!-- Edoras -->
  <div
    v-if="appConfig.VueSettingsPreRun.DiscountTimerLayoutSolo === 'Edoras'"
    class="Edoras v-mb-sm"
    :style="discountTimerBackground"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div
        v-for="discount in discountsData"
        :key="discount.ID"
        class="v-item-discount-wrapper"
        data-test-id="discount-timer-item"
      >
        <div class="v-image">
          <arora-nuxt-image
            :alt="discount.ActionProductData.Name"
            :image="discount.ActionProductData.SmallImage"
            image-type="ProductSmall"
          />
        </div>
        <div class="v-discount-timers-summary-info">
          <div>
            <div
              class="v-title"
              v-html="sanitize(discount.ActionProductData.Name)"
            />
            <div
              class="v-description v-mb-xs"
              v-html="sanitize(discount.ActionProductData.Description)"
            />
          </div>

          <div class="v-discount-timers-summary-bottom">
            <div
              class="v-timer-block v-mb-sm"
              v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
            >
              <div
                class="v-timer-name v-mb-sm"
                v-html="translate('discountTimersPage.timerName')"
              />
              <common-countdown :expires="discount.TimeEndSecTS" />
            </div>

            <div class="v-price-block">
              <arora-button
                :label="translate('addToCartButton.addToCart')"
                data-test-id="discount-timer-add-to-cart"
                @click="
                  async () =>
                    await addToBasket(
                      discount.ActionProductData.ID,
                      discount.ID,
                      discount.GroupID,
                      discount.ActionProductData.Price,
                      1,
                      discount.ID
                    )
                "
              >
                <span class="v-price v-old">
                  <common-currency
                    strikethrough
                    :amount="
                      discount.CurrentProductData.HasOptions &&
                      discount.CurrentProductOptionID !== Guid.Empty
                        ? discount.CurrentProductData.Price +
                          discount.ProductsInfo[discount.CurrentProductOptionID].Price
                        : discount.CurrentProductData.Price
                    "
                  />
                </span>
                <span class="v-price">
                  <common-currency :amount="discount.ActionProductData.Price" />
                </span>
              </arora-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end Edoras -->

  <!-- Adorn -->
  <div
    v-else-if="appConfig.VueSettingsPreRun.DiscountTimerLayoutSolo === 'Adorn'"
    class="Adorn v-mb-sm"
    :style="discountTimerBackground"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div
        v-for="discount in discountsData"
        :key="discount.ID"
        class="v-item-discount-wrapper"
        data-test-id="discount-timer-item"
      >
        <div class="v-image">
          <arora-nuxt-image
            :alt="discount.ActionProductData.Name"
            :image="discount.ActionProductData.SmallImage"
            image-type="ProductSmall"
          />
        </div>
        <div class="v-discount-timers-summary-info">
          <div>
            <div
              class="v-title-section"
              v-html="translate('discountTimersPage.title')"
            />
            <div
              class="v-title"
              v-html="sanitize(discount.ActionProductData.Name)"
            />
            <div
              class="v-description v-mb-xs"
              v-html="sanitize(discount.ActionProductData.Description)"
            />
          </div>

          <div class="v-discount-timers-summary-bottom">
            <div class="v-price-block">
              <arora-button
                class-name="v-discount-btn-adorn"
                :label="translate('addToCartButton.addToCart')"
                data-test-id="discount-timer-add-to-cart"
                @click="
                  async () =>
                    await addToBasket(
                      discount.ActionProductData.ID,
                      discount.ID,
                      discount.GroupID,
                      discount.ActionProductData.Price,
                      1,
                      discount.ID
                    )
                "
              >
                <span class="v-icon v-mr-sm">
                  <icon-menu-basket />
                </span>
                <span class="v-price">
                  <common-currency :amount="discount.ActionProductData.Price" />
                </span>
                <span class="v-price v-old">
                  <common-currency
                    strikethrough
                    :amount="
                      discount.CurrentProductData.HasOptions &&
                      discount.CurrentProductOptionID !== Guid.Empty
                        ? discount.CurrentProductData.Price +
                          discount.ProductsInfo[discount.CurrentProductOptionID].Price
                        : discount.CurrentProductData.Price
                    "
                  />
                </span>
              </arora-button>
            </div>
            <div
              class="v-timer-block v-mb-sm"
              v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
            >
              <div
                class="v-timer-name v-mb-sm"
                v-html="translate('discountTimersPage.timerName')"
              />
              <common-countdown :expires="discount.TimeEndSecTS" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end Adorn -->

  <!-- Andrast -->
  <div
    v-else-if="appConfig.VueSettingsPreRun.DiscountTimerLayoutSolo === 'Andrast'"
    class="Andrast v-mb-sm"
    :style="discountTimerBackground"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div
        v-for="discount in discountsData"
        :key="discount.ID"
        class="v-item-discount-wrapper"
        data-test-id="discount-timer-item"
      >
        <div class="v-image">
          <arora-nuxt-image
            :alt="discount.ActionProductData.Name"
            :image="discount.ActionProductData.SmallImage"
            image-type="ProductSmall"
          />
        </div>
        <div class="v-discount-timers-summary-info">
          <div class="v-discount-timers-summary-info-top">
            <div
              class="v-title-section v-mb-sm"
              v-html="translate('discountTimersPage.title')"
            />
            <div
              class="v-timer-block v-mb-sm"
              v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
            >
              <div
                class="v-timer-name"
                v-html="translate('discountTimersPage.timerName')"
              />
              <common-countdown :expires="discount.TimeEndSecTS" />
            </div>
          </div>

          <div class="v-mb-sm">
            <div
              class="v-title"
              v-html="sanitize(discount.ActionProductData.Name)"
            />
            <div
              class="v-description v-mb-xs"
              v-html="sanitize(discount.ActionProductData.Description)"
            />
          </div>

          <div class="v-discount-timers-summary-bottom">
            <arora-button
              class-name="v-discount-btn-andrast"
              :label="translate('addToCartButton.addToCart')"
              data-test-id="discount-timer-add-to-cart"
              @click="
                async () =>
                  await addToBasket(
                    discount.ActionProductData.ID,
                    discount.ID,
                    discount.GroupID,
                    discount.ActionProductData.Price,
                    1,
                    discount.ID
                  )
              "
            >
              <span v-html="translate('discountTimersPage.btnText')" />
              <span class="v-price">
                <common-currency :amount="discount.ActionProductData.Price" />
              </span>
              <span class="v-price v-old">
                <common-currency
                  strikethrough
                  :amount="
                    discount.CurrentProductData.HasOptions &&
                    discount.CurrentProductOptionID !== Guid.Empty
                      ? discount.CurrentProductData.Price +
                        discount.ProductsInfo[discount.CurrentProductOptionID].Price
                      : discount.CurrentProductData.Price
                  "
                />
              </span>
            </arora-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end Andrast -->
</template>

<script setup lang="ts">
import type { CurrentDiscount } from '~types/menuStore'

import type { GUID } from '@arora/common'

import { FirstAddType, Guid } from '~api/consts'

defineProps<{
  discountsData: CurrentDiscount[]
}>()

const { sanitize, translate } = useI18nSanitized()

const { addToCart } = useCartFunctionsWithDialogs()
const appConfig = useAppConfig()

const discountTimerBackground = computed<string>(() => {
  if (appConfig.VueSettingsPreRun.DiscountTimerBackground) {
    return `background-image: url(${appConfig.VueSettingsPreRun.DiscountTimerBackground.Path.replaceAll('\\', '/')});`
  }

  return 'background-image: none;'
})

async function addToBasket(
  ID: GUID,
  GroupId: GUID,
  Name: string,
  Price: number,
  Count: number,
  discountTimeItemId: GUID
): Promise<void> {
  await addToCart(ID, FirstAddType.Default, GroupId, Name, Price, null, null, Count, discountTimeItemId)
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.Edoras {
  padding-top: 15px;
  padding-bottom: 1.1rem;

  .v-item-discount-wrapper {
    flex: 0 0 60%;
    max-width: 60%;
    margin: 0 auto;
    box-shadow: variables.$CardShadow;
    border-radius: variables.$BorderRadius;
    display: flex;
    flex-direction: row;
    background: variables.$DiscountTimerWrapperBackgroundColor;
    color: variables.$DiscountTimerWrapperColor;

    .v-image {
      flex: 0 0 35%;
      max-width: 35%;
      border-radius: variables.$BorderRadius 0 0 variables.$BorderRadius;
      overflow: hidden;
    }

    .v-discount-timers-summary-info {
      flex: 0 0 65%;
      max-width: 65%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .v-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .v-description {
        font-size: 1.15rem;
        font-weight: 500;
      }

      .v-discount-timers-summary-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      .v-timer-block {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @include mixins.md {
    .v-item-discount-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;

      .v-image {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: variables.$BorderRadius variables.$BorderRadius 0 0;
        overflow: hidden;
      }

      .v-discount-timers-summary-info {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.Adorn {
  padding-top: 15px;
  padding-bottom: 1.1rem;

  .v-item-discount-wrapper {
    flex: 0 0 65%;
    max-width: 65%;
    margin: 0 auto;
    box-shadow: variables.$CardShadow;
    border-radius: variables.$BorderRadius;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: variables.$DiscountTimerWrapperBackgroundColor;
    color: variables.$DiscountTimerWrapperColor;

    .v-image {
      flex: 0 0 35%;
      max-width: 35%;
      border-radius: variables.$BorderRadius 0 0 variables.$BorderRadius;
      overflow: hidden;
    }

    .v-discount-timers-summary-info {
      flex: 0 0 65%;
      max-width: 65%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .v-title-section {
        font-weight: 700;
        font-size: 1.7rem;
        color: variables.$PrimaryBackgroundColor;
        margin-bottom: 4px;
      }

      .v-title {
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: 12px;
      }

      .v-description {
        font-weight: 500;
        font-size: 1.15rem;
        opacity: 0.6;
      }

      .v-discount-timers-summary-bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        @include mixins.sm {
          justify-content: center;
          align-items: center;
          gap: 12px;
        }

        .v-discount-btn-adorn {
          padding: 8px 3.75rem;
          display: inline-flex;
          align-items: center;

          .v-price {
            font-weight: 500;
            font-size: 20px;
            margin-right: 4px;

            &.v-old {
              font-weight: 400;
              font-size: 16px;
              opacity: 0.5;
            }
          }
        }
      }

      .v-timer-block {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @include mixins.md {
    .v-item-discount-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;

      .v-image {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: variables.$BorderRadius variables.$BorderRadius 0 0;
        overflow: hidden;
      }

      .v-discount-timers-summary-info {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.Andrast {
  padding-top: 15px;
  padding-bottom: 1.1rem;

  .v-item-discount-wrapper {
    flex: 0 0 65%;
    max-width: 65%;
    margin: 0 auto;
    box-shadow: variables.$CardShadow;
    border-radius: variables.$BorderRadius;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: variables.$DiscountTimerWrapperBackgroundColor;
    color: variables.$DiscountTimerWrapperColor;

    .v-image {
      flex: 0 0 35%;
      max-width: 35%;
      border-radius: variables.$BorderRadius 0 0 variables.$BorderRadius;
      overflow: hidden;
    }

    .v-discount-timers-summary-info {
      flex: 0 0 65%;
      max-width: 65%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .v-discount-timers-summary-info-top {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @include mixins.sm {
          justify-content: center;
        }

        .v-title-section {
          font-weight: 700;
          font-size: 24px;
          color: variables.$PrimaryBackgroundColor;
        }

        .v-timer-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          padding: 12px;

          &:after {
            border-radius: variables.$BorderRadius;
            position: absolute;
            content: '';
            background: variables.$DiscountTimerColor;
            opacity: 0.1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }

          .v-timer-name {
            margin-right: 8px;
            z-index: 2;
            position: relative;
          }

          .v-countdown-block {
            z-index: 2;
            position: relative;
          }
        }
      }

      .v-title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 12px;
      }

      .v-description {
        font-weight: 400;
        font-size: 16px;
        opacity: 0.6;
      }

      .v-discount-timers-summary-bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        @include mixins.sm {
          justify-content: center;
          align-items: center;
          gap: 12px;
        }

        .v-discount-btn-andrast {
          width: 100%;
          font-weight: 500;
          font-size: 18px;

          .v-price {
            font-weight: 500;
            font-size: 20px;
            margin-right: 4px;

            &.v-old {
              font-weight: 400;
              font-size: 16px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  @include mixins.xl {
    .v-item-discount-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @include mixins.md {
    .v-item-discount-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;

      .v-image {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: variables.$BorderRadius variables.$BorderRadius 0 0;
        overflow: hidden;
      }

      .v-discount-timers-summary-info {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
