<i18n>
ru:
  errorCartInvalid: Некоторые товары в корзине недоступны для заказа. Проверьте корзину.
  errorNoAddress: Укажите адрес доставки.
  errorNoScheduled: 'По выбранному адресу самовывоз возможен только с {from} по {to}.'
  errorNoScheduledDelivery: 'По выбранному адресу доставка возможна только с {from} по {to}.'
  errorWrongDeliveryInput: 'Адрес не найден. Введите адрес, который входит в зону доставки.'
  infoDeliveryTemporarilyUnavailable: Доставка курьером временно недоступна. Можете оформить заказ на самовывоз.
  infoSelfServiceTemporarilyUnavailable: Самовывоз временно недоступен. Можете оформить заказ на доставку.
  infoTerminalTemporarilyNonActive: >-
    Оформление заказа в этой зоне временно недоступно. Можете оформить заказ по
    другому адресу.
ua:
  errorCartInvalid: Деякі товари недоступні для замовлення. Перевірте кошик.
  errorNoAddress: Вкажіть адресу доставки
  errorNoScheduled: 'За обраною адресою самовивіз можливий тільки з {from} до {to}.'
  errorNoScheduledDelivery: 'За обраною адресою доставка можлива тільки з {from} до {to}.'
  errorWrongDeliveryInput: 'Адресу не знайдено, введіть адресу, яка входить в зону доставки.'
  infoDeliveryTemporarilyUnavailable: Доставка кур'єром зараз недоступна. Можете оформити замовлення на самовивіз.
  infoSelfServiceTemporarilyUnavailable: Самовивіз зараз недоступний. Можете оформити замовлення на доставку.
  infoTerminalTemporarilyNonActive: >-
    Оформлення замовлення за даною зоною доставки тимчасово недоступне. Ви
    можете оформити замовлення за іншою адресою.
us:
  errorCartInvalid: Some items in your order are unavailable for purchase. Check your cart.
  errorNoAddress: Please select your address
  errorNoScheduled: 'You can order delivery on this self-service point from {from} to {to}.'
  errorNoScheduledDelivery: 'You can order delivery to this address from {from} to {to}.'
  errorWrongDeliveryInput: Address not found. Select an address inside delivery zone.
  infoDeliveryTemporarilyUnavailable: Delivery is currently unavailable. Please place an order for pickup.
  infoSelfServiceTemporarilyUnavailable: Pickup is currently unavailable. Please place an order for delivery.
  infoTerminalTemporarilyNonActive: >-
    Ordering in this area is temporary unavailable. You can place an order at a
    different address.
</i18n>

<template>
  <div class="v-error-wrapper">
    <div
      v-if="
        !showOnlyInfoMessage &&
        ((!hideNonInputErrors && (errorCartInvalid || errorNoScheduled)) ||
          errorNoAddress ||
          errorWrongDeliveryInput)
      "
      class="v-error-block v-error-color"
    >
      <icon-general-exclamation-circle class="v-error-icon" />
      <ul class="v-list-error">
        <TransitionGroup
          appear
          name="fade"
        >
          <li
            v-if="!hideNonInputErrors && errorCartInvalid"
            class="v-list-error-item"
            v-html="errorCartInvalid"
          />
          <li
            v-if="!hideNonInputErrors && errorNoScheduled"
            class="v-list-error-item"
            v-html="errorNoScheduled"
          />
          <li
            v-if="errorNoAddress"
            class="v-list-error-item"
            v-html="errorNoAddress"
          />
          <li
            v-if="errorWrongDeliveryInput"
            class="v-list-error-item"
            v-html="errorWrongDeliveryInput"
          />
        </TransitionGroup>
      </ul>
    </div>
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="terminalTemporarilyNoActive.IsTerminalNoActive"
        class="v-info-block"
      >
        <span class="v-info-icon"><icon-general-information-circle /></span>
        <div
          class="v-info-content"
          v-html="infoMessage"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'

const { hideNonInputErrors } = defineProps<{
  hideNonInputErrors?: boolean
  showOnlyInfoMessage?: boolean // для отображения оповещений о недоступности терминала на первом шаге двухстадийной корзины
}>()

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const clientStore = useClientStore()
const { fromMillisInZone } = useDateTime()
const { noAddress, terminalTemporarilyNoActive } = useAddressSelector()

const errorCartInvalid = ref<string | null>(null)
const errorNoAddress = ref<string | null>(null)
const errorNoScheduled = ref<string | null>(null)
const errorWrongDeliveryInput = ref<string | null>(null)

const infoMessage = computed<string | null>(() => {
  if (terminalTemporarilyNoActive.value.TemporarilyUnavailableForSelfService) {
    return translate('errorNotifier.infoSelfServiceTemporarilyUnavailable')
  } else if (terminalTemporarilyNoActive.value.TemporarilyUnavailableForDelivery) {
    return translate('errorNotifier.infoDeliveryTemporarilyUnavailable')
  } else if (terminalTemporarilyNoActive.value.IsTemporarilyNonActive) {
    return translate('errorNotifier.infoTerminalTemporarilyNonActive')
  }

  return null
})

watch(
  () => clientStore.ClientState.data?.Cart?.Forbidden,
  (newValue) => {
    errorCartInvalid.value = newValue ? translate('errorNotifier.errorCartInvalid') : null
  }
)

watch(
  () => clientStore.TimeRestrictions.data?.Scheduled?.EnabledByTerminalSettings,
  (enabledByTerminalSettings, oldEnabledByTerminalSettings) => {
    if (oldEnabledByTerminalSettings === undefined) return // after initialization oldEnabledByTerminalSettings is null
    if (enabledByTerminalSettings === undefined) return // in case of client state update error

    if (enabledByTerminalSettings) {
      errorNoScheduled.value = null
    } else {
      const key = DateTime.now().toFormat('yyyyMMdd')
      const periods =
        clientStore.TimeRestrictions.data?.Scheduled?.Schedule?.Dates[key]?.RestrictedPeriods[0]

      if (periods) {
        const from = fromMillisInZone(periods.Start, appConfig.RestaurantSettingsPreRun.GMT)
        const to = fromMillisInZone(periods.End, appConfig.RestaurantSettingsPreRun.GMT)

        errorNoScheduled.value =
          clientStore.selfService || clientStore.inHall
            ? translate('errorNotifier.errorNoScheduled', {
                from: from.toFormat('T'),
                to: to.toFormat('T')
              })
            : translate('errorNotifier.errorNoScheduledDelivery', {
                from: from.toFormat('T'),
                to: to.toFormat('T')
              })
      }
    }
  }
)

watch(
  () => clientStore.courierDelivery,
  (newValue) => {
    if (newValue) {
      if (noAddress.value) {
        errorNoAddress.value = translate('errorNotifier.errorNoAddress')
      } else if (clientStore.ClientState.data?.DeliveryInputError) {
        errorWrongDeliveryInput.value = translate('errorNotifier.errorWrongDeliveryInput')
      }
    } else {
      errorNoAddress.value = null
      errorWrongDeliveryInput.value = null
    }
  }
)

watch(
  () => clientStore.ClientState.data?.SelectedDeliveryAddress,
  (address, oldAddress) => {
    if (oldAddress === undefined) return // after initialization oldContent is null
    if (address === undefined) return // in case of client state update error

    errorNoAddress.value =
      clientStore.courierDelivery && noAddress.value ? translate('errorNotifier.errorNoAddress') : null
  }
)

watch(
  () => clientStore.ClientState.data?.DeliveryInputError,
  (newValue) => {
    errorWrongDeliveryInput.value =
      clientStore.courierDelivery && newValue ? translate('errorNotifier.errorWrongDeliveryInput') : null
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-error-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 1.1rem;

  .v-error-block {
    display: flex;
    align-items: center;

    .v-error-icon {
      width: 1.1rem;
      height: 1.1rem;
      fill: none;
    }

    .v-list-error {
      margin-top: 0;
      margin-left: 0.75rem;
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      .v-list-error-item {
        padding: 4px 0;
      }
    }
  }

  .v-info-block {
    color: variables.$WarningColor;
    margin: 1rem 0;
    display: flex;

    .v-info-content {
      padding: 0 15px;
    }
  }
}
</style>
