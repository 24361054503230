<i18n>
ru:
  divider: ':'
ua:
  divider: ':'
us:
  divider: ':'
</i18n>

<template>
  <div
    class="v-countdown-block"
    role="timer"
  >
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div v-if="showHourBlock">
        <div
          class="v-countdown-digit-wrapper"
          :class="[
            usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
          ]"
        >
          <span
            class="v-countdown-digit-base"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default'
            ]"
          >
            {{ animationHours[0] }}
          </span>
          <div
            class="v-countdown-digit-flap over front"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="previousHours[0]"
            v-show="animationHours[0] !== hours[0]"
          />
          <div
            class="v-countdown-digit-flap over back"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="hours[0]"
            v-show="animationHours[0] !== hours[0]"
          />
          <div
            class="v-countdown-digit-flap under"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="hours[0]"
            v-show="animationHours[0] !== hours[0]"
          />
        </div>

        <div
          class="v-countdown-digit-wrapper"
          :class="[
            usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
          ]"
        >
          <span
            class="v-countdown-digit-base"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default'
            ]"
          >
            {{ animationHours[1] }}
          </span>
          <div
            class="v-countdown-digit-flap over front"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="previousHours[1]"
            v-show="animationHours[1] !== hours[1]"
          />
          <div
            class="v-countdown-digit-flap over back"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="hours[1]"
            v-show="animationHours[1] !== hours[1]"
          />
          <div
            class="v-countdown-digit-flap under"
            :class="[
              usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default'
            ]"
            :data-content="hours[1]"
            v-show="animationHours[1] !== hours[1]"
          />
        </div>

        <div
          class="v-countdown-colon"
          v-html="translate('countdown.divider')"
        />
      </div>
    </transition>

    <div
      class="v-countdown-digit-wrapper"
      :class="[
        usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
      ]"
    >
      <span
        class="v-countdown-digit-base"
        :class="[usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default']"
      >
        {{ animationMinutes[0] }}
      </span>
      <div
        class="v-countdown-digit-flap over front"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="previousMinutes[0]"
        v-show="animationMinutes[0] !== minutes[0]"
      />
      <div
        class="v-countdown-digit-flap over back"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="minutes[0]"
        v-show="animationMinutes[0] !== minutes[0]"
      />
      <div
        class="v-countdown-digit-flap under"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="minutes[0]"
        v-show="animationMinutes[0] !== minutes[0]"
      />
    </div>

    <div
      class="v-countdown-digit-wrapper"
      :class="[
        usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
      ]"
    >
      <span
        class="v-countdown-digit-base"
        :class="[usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default']"
      >
        {{ animationMinutes[1] }}
      </span>
      <div
        class="v-countdown-digit-flap over front"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="previousMinutes[1]"
        v-show="animationMinutes[1] !== minutes[1]"
      />
      <div
        class="v-countdown-digit-flap over back"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="minutes[1]"
        v-show="animationMinutes[1] !== minutes[1]"
      />
      <div
        class="v-countdown-digit-flap under"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="minutes[1]"
        v-show="animationMinutes[1] !== minutes[1]"
      />
    </div>

    <div
      class="v-countdown-colon"
      v-html="translate('countdown.divider')"
    />

    <div
      class="v-countdown-digit-wrapper"
      :class="[
        usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
      ]"
    >
      <span
        class="v-countdown-digit-base"
        :class="[usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default']"
      >
        {{ animationSeconds[0] }}
      </span>
      <div
        class="v-countdown-digit-flap over front"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="previousSeconds[0]"
        v-show="animationSeconds[0] !== seconds[0]"
      />
      <div
        class="v-countdown-digit-flap over back"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="seconds[0]"
        v-show="animationSeconds[0] !== seconds[0]"
      />
      <div
        class="v-countdown-digit-flap under"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="seconds[0]"
        v-show="animationSeconds[0] !== seconds[0]"
      />
    </div>

    <div
      class="v-countdown-digit-wrapper"
      :class="[
        usePrimaryColor ? 'v-countdown-digit-wrapper-primary' : 'v-countdown-digit-wrapper-default'
      ]"
    >
      <span
        class="v-countdown-digit-base"
        :class="[usePrimaryColor ? 'v-countdown-digit-base-primary' : 'v-countdown-digit-base-default']"
      >
        {{ animationSeconds[1] }}
      </span>
      <div
        class="v-countdown-digit-flap over front"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="previousSeconds[1]"
        v-show="animationSeconds[1] !== seconds[1]"
      />
      <div
        class="v-countdown-digit-flap over back"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="seconds[1]"
        v-show="animationSeconds[1] !== seconds[1]"
      />
      <div
        class="v-countdown-digit-flap under"
        :class="[usePrimaryColor ? 'v-countdown-digit-flap-primary' : 'v-countdown-digit-flap-default']"
        :data-content="seconds[1]"
        v-show="animationSeconds[1] !== seconds[1]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EmitterEvent } from '~types/common'

import { DateTime, type Duration } from 'luxon'

const { expires, onTimerEndEvent } = defineProps<{
  expires: number
  onTimerEndEvent?: EmitterEvent
  usePrimaryColor?: boolean
}>()

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const { eventEmit } = useEmitter()
const { fromMillisInZone } = useDateTime()

const expirationDate = ref<DateTime>(DateTime.now())
const tickingTime = ref<Duration | null>(null)
const showHourBlock = ref<boolean>(false)

const previousHours = ref<string>('0')
const previousMinutes = ref<string>('0')
const previousSeconds = ref<string>('0')

//Made for animation. Their whole purpose is to be assigned with 350ms delay
const animationHours = ref<string>('0')
const animationMinutes = ref<string>('0')
const animationSeconds = ref<string>('0')

const timerTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

onMounted(async () => {
  await calculateTimer()
})

async function calculateTimer(): Promise<void> {
  expirationDate.value = fromMillisInZone(expires * 1000, appConfig.RestaurantSettingsPreRun.GMT)
  showHourBlock.value = Math.floor(expirationDate.value.diffNow(['hours']).hours) > 0

  if (Math.floor(expirationDate.value.diffNow(['seconds']).seconds) > 0) {
    timerTimeout.value = setInterval(timerTick, 1000)
  }
}

watch(
  () => expires,
  async (newState: number, oldState: number) => {
    if (newState && newState !== oldState) {
      await calculateTimer()
    }
  }
)

const hours = computed<string>(() => {
  const localHours = tickingTime.value && tickingTime.value.hours > 0 ? tickingTime.value.hours : 0

  if (localHours >= 99) return '99'

  let str = Math.floor(localHours).toString()
  if (str.length === 1) str = `0${str}`

  return str
})
const minutes = computed<string>(() => {
  const localMinutes = tickingTime.value && tickingTime.value.minutes > 0 ? tickingTime.value.minutes : 0

  let str = Math.floor(localMinutes).toString()
  if (str.length === 1) str = `0${str}`

  return str
})
const seconds = computed<string>(() => {
  const localSeconds = tickingTime.value && tickingTime.value.seconds > 0 ? tickingTime.value.seconds : 0

  let str = Math.floor(localSeconds).toString()
  if (str.length === 1) str = `0${str}`

  return str
})

function timerTick(): void {
  previousHours.value = hours.value
  previousMinutes.value = minutes.value
  previousSeconds.value = seconds.value

  tickingTime.value = expirationDate.value.diffNow(['hours', 'minutes', 'seconds'])

  if (
    Math.floor(tickingTime.value.hours) <= 0 &&
    Math.floor(tickingTime.value.minutes) <= 0 &&
    Math.floor(tickingTime.value.seconds) <= 0
  ) {
    if (timerTimeout.value) clearTimeout(timerTimeout.value)

    if (onTimerEndEvent) eventEmit(onTimerEndEvent)
  } else {
    showHourBlock.value = Math.floor(tickingTime.value.hours) > 0

    if (previousHours.value !== hours.value) {
      setTimeout(() => {
        animationHours.value = hours.value
      }, 350)
    }

    if (previousMinutes.value !== minutes.value) {
      setTimeout(() => {
        animationMinutes.value = minutes.value
      }, 350)
    }

    if (previousSeconds.value !== seconds.value) {
      setTimeout(() => {
        animationSeconds.value = seconds.value
      }, 350)
    }
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-countdown-digit-wrapper {
  position: relative;
  float: left;
  width: 22px;
  height: 28px;
  border-radius: 3px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;

  &-primary {
    background-color: variables.$PrimaryBackgroundColor;
  }

  &-default {
    background-color: variables.$DiscountTimerBackgroundColor;
  }
}

.v-countdown-colon {
  position: relative;
  float: left;
  width: 5px;
  height: 28px;
  text-align: center;
  font-size: 22px;
}

.v-countdown-digit-base {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-primary {
    color: variables.$PrimaryColor;
  }

  &-default {
    color: variables.$DiscountTimerColor;
  }
}

.v-countdown-digit-flap {
  display: block;
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  border-radius: 3px 3px 0 0;
  transform-origin: 50% 100%;
  backface-visibility: hidden;
  overflow: hidden;

  &-primary {
    background-color: variables.$PrimaryBackgroundColor;

    &.front {
      animation: flip-down-front-primary 300ms ease-in both;
    }

    &.back {
      animation: flip-down-back-primary 300ms ease-in both;
    }

    &.under {
      animation: fade-under-primary 300ms ease-in both;
    }
  }

  &-default {
    background-color: variables.$DiscountTimerBackgroundColor;

    &.front {
      animation: flip-down-front 300ms ease-in both;
    }

    &.back {
      animation: flip-down-back 300ms ease-in both;
    }

    &.under {
      animation: fade-under 300ms ease-in both;
    }
  }

  &::before {
    content: attr(data-content);
    position: absolute;
    left: 50%;
  }

  &.front::before,
  &.under::before {
    top: 100%;
    transform: translate(-50%, -50%);
  }

  &.back {
    transform: rotateY(180deg);

    &::before {
      top: 100%;
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }

  &.over {
    z-index: 2;
  }

  &.under {
    z-index: 1;
  }
}

@keyframes flip-down-front {
  0% {
    transform: rotateX(0deg);
    background-color: variables.$DiscountTimerBackgroundColor;
    color: variables.$DiscountTimerColor;
  }
  100% {
    transform: rotateX(-180deg);
    background-color: variables.$DiscountTimerBackgroundColorDark;
    color: variables.$DiscountTimerColorDark;
  }
}

@keyframes flip-down-back {
  0% {
    transform: rotateY(180deg) rotateX(0deg);
    background-color: variables.$DiscountTimerBackgroundColorDark;
    color: variables.$DiscountTimerColorDark;
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
    background-color: variables.$DiscountTimerBackgroundColor;
    color: variables.$DiscountTimerColor;
  }
}

@keyframes fade-under {
  0% {
    background-color: variables.$DiscountTimerBackgroundColorDark;
    color: variables.$DiscountTimerColorDark;
  }
  100% {
    background-color: variables.$DiscountTimerBackgroundColor;
    color: variables.$DiscountTimerColor;
  }
}

@keyframes flip-down-front-primary {
  0% {
    transform: rotateX(0deg);
    background-color: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
  }
  100% {
    transform: rotateX(-180deg);
    background-color: variables.$PrimaryBackgroundColorDarken35;
    color: variables.$DiscountTimerColorDark;
  }
}

@keyframes flip-down-back-primary {
  0% {
    transform: rotateY(180deg) rotateX(0deg);
    background-color: variables.$PrimaryBackgroundColorDarken35;
    color: variables.$DiscountTimerColorDark;
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
    background-color: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
  }
}

@keyframes fade-under-primary {
  0% {
    background-color: variables.$PrimaryBackgroundColorDarken35;
    color: variables.$DiscountTimerColorDark;
  }
  100% {
    background-color: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
  }
}

.v-countdown-block {
  pointer-events: none;
  margin: 0 auto;
  display: flex;
  gap: 4px;

  .v-countdown-digit {
    margin-right: 3px;

    &:nth-child(2n + 2) {
      margin-right: 6px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
