<i18n>
ru:
  search: Поиск
  yourCity: 'Ваш город:'
ua:
  search: Пошук
  yourCity: 'Ваше місто:'
us:
  search: Search
  yourCity: 'Your city:'
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div class="title">
      <span v-html="translate('citySelectorPopup.yourCity')" />
      <span
        class="v-city-popup-title-name"
        v-html="sanitize(cityName)"
      />
    </div>
  </div>

  <div class="v-city-select-popup">
    <div
      v-if="appConfig.VueSettingsPreRun.CitySelectShowSearch"
      class="v-col-auto"
    >
      <v-input
        class-name="v-arora-input"
        focus-on-mount
        show-left-end
        :label="translate('citySelectorPopup.search')"
        enterkeyhint="search"
        v-model:text="search"
      >
        <template #left-end> <icon-general-search /> </template>
        <template #label> <span v-html="translate('citySelectorPopup.search')" /> </template>
      </v-input>
    </div>
    <div :class="{ 'v-city-list v-scrollbar': appConfig.VueSettingsPreRun.CitySelectShowSearch }">
      <ul
        v-if="restaurantData.length > 0"
        class="v-city-select-list"
        :class="{
          'list-row': appConfig.VueSettingsPreRun.CitySelectDisplay === 'row',
          'two-columns': appConfig.VueSettingsPreRun.CitySelectDisplay === 'two-columns',
          columns: appConfig.VueSettingsPreRun.CitySelectDisplay === 'columns'
        }"
      >
        <li
          v-for="rest in restaurantData"
          :key="sanitize(rest.ID)"
          class="city-select-item"
          :class="{ 'active-rest': rest.ID === appConfig.CurrentRestaurantId }"
        >
          <arora-nuxt-link
            class-name="city-select-link"
            external-link
            :disabled="rest.ID === appConfig.CurrentRestaurantId"
            :href="makeLink(rest)"
            :label="rest.Name"
          />
        </li>
      </ul>
      <span
        v-else
        class="v-self-service-terminal-no-results"
        v-html="translate('selfServiceTerminals.searchNoResults')"
      />
    </div>
    <div
      v-if="restaurantData.length > 2"
      class="v-scroll-gradient-popup"
    />
  </div>
</template>

<script setup lang="ts">
import type { Restaurant } from '~types/addressStore'

import { type GUID, useCommon, VInput } from '@arora/common'

const { sanitize, translate } = useI18nSanitized()
const { cityName, makeLink } = useAddressSelector()

const appConfig = useAppConfig()
const { keyboardSearchCheck } = useKeyboardCheck()
const { stringIsNullOrWhitespace } = useCommon()

const externalLinksAddress = appConfig.VueSettingsPreRun.ExternalLinks.filter(
  (l) => l.Type === 'address'
)

const search = ref<string | null>(null)
const restaurantData = ref<Restaurant[]>([])
const addressStore = useAddressStore()
const loaded = computed(() => addressStore.Restaurants?.data !== null)

onMounted(async () => {
  if (loaded.value) {
    search.value = ''
  } else {
    await addressStore.initRestaurant()
  }
})

watch(
  () => loaded.value,
  async (newState: boolean) => {
    if (newState) {
      search.value = ''
    }
  }
)
watch(
  () => search.value,
  async (value: string | null) => {
    const restaurants: Restaurant[] = addressStore.Restaurants.data ?? []

    let total = [] as Restaurant[]

    if (externalLinksAddress.length > 0)
      for (const link of externalLinksAddress) {
        total.push({
          Active: true,
          ExternalLink: link.Url,
          ID: '' as GUID,
          Name: link.Name ?? '',
          ShowRestaurantInSelect: true,
          SortWeight: link.SortWeight
        })
      }

    if (restaurants) {
      total = [
        ...total,
        ...Object.values(restaurants).filter((rest) => {
          return rest.Active && rest.ShowRestaurantInSelect
        })
      ]
    }

    const result = stringIsNullOrWhitespace(value)
      ? total
      : total.filter((item: Restaurant) => keyboardSearchCheck(item.Name, value))

    restaurantData.value = result.sort((a, b) => {
      return a.SortWeight > b.SortWeight ? 1 : -1 //sort as in the back
    })
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-city-popup-head {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1.1rem;
}

.v-city-popup-title-name {
  margin-left: 4px;
}

.v-city-select-popup {
  position: relative;
}

.v-city-list {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-city-popup {
  position: relative;
}

.city-select-item {
  &:hover {
    .city-select-link {
      background-color: variables.$PrimaryBackgroundColor;
      color: variables.$PrimaryColor;
    }
  }
}

.v-city-select-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0 0 35px;

  .city-select-link {
    display: flex;
    height: auto;
    position: relative;
    border: variables.$BorderWidth solid rgba(0, 0, 0, 0.08);
    border-radius: variables.$BorderRadius;
    line-height: 1.2;
    padding: 10px 20px;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &.list-row {
  }

  &.columns {
    .city-select-item {
      flex: 0 0 100%;
      max-width: 100%;

      &.active-rest {
        .city-select-link {
          background: variables.$PrimaryBackgroundColor;
          color: variables.$PrimaryColor;

          &:hover {
            pointer-events: none;
          }
        }
      }

      .city-select-link {
        text-align: center;
      }
    }
  }

  &.two-columns {
    align-items: stretch;

    .city-select-item {
      text-align: center;

      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);

      .city-select-link {
        height: 100%;
      }
    }
  }
}
</style>
