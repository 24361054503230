<template>
  <div
    v-if="error"
    class="v-error-color"
    v-html="error"
  />
  <div
    v-else
    :id="uid"
    class="v-re-captcha-visible v-text-center"
    :inert="disabled"
  />
</template>

<script setup lang="ts">
import type { CanBeDisabled } from '@arora/common'

defineProps<CanBeDisabled>()

const captchaV2Id: Readonly<string> = 'cje-google-recaptcha-script'
const captchaV3Id: Readonly<string> = 'cje-google-recaptcha-script-v3'

const apiUrl: Readonly<string> = 'https://www.google.com/recaptcha/api.js'

const widgetIds: Record<string, number> = {}

const restaurantStore = useRestaurantStore()
const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { eventOn } = useEmitter()

const uid = Math.random().toString(36)
const error = ref<string | null>(null)

const reCAPTCHAv3 = computed<string>(() => appConfig.RestaurantSettingsPreRun.ReCaptchaV3SiteKey ?? '')
const reCAPTCHAv2 = computed<string>(() => appConfig.RestaurantSettingsPreRun.ReCaptchaV2SiteKey ?? '')

onMounted(() => {
  eventOn('v-reset-captcha', () => {
    ResetReCAPTCHA(uid)
  })

  try {
    if (reCAPTCHAv3.value && !appConfig.RestaurantSettingsPreRun.ReCaptchaForceV2) {
      CreateReCAPTCHA(uid, reCAPTCHAv3.value, true)
    } else if (reCAPTCHAv2.value) {
      CreateReCAPTCHA(uid, reCAPTCHAv2.value, false)
      restaurantStore.setGoogleCaptchaV2(true)
    }
  } catch (error) {
    console.error('Error creating captcha', error)
  }
})

function explicitlyRenderReCAPTCHAv2(uid: string, key: string): void {
  if (key && typeof grecaptcha !== 'undefined') {
    const darkTheme = menuStore.CurrentGroup?.UseAlternateTheme
      ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
      : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme

    widgetIds[uid] = grecaptcha.render(uid, {
      callback: verifyCallback,
      sitekey: key,
      theme: darkTheme ? 'dark' : 'light'
    })
  }
}

function verifyCallback(response: string): void {
  restaurantStore.saveGoogleCaptcha(response)
}

function FailedAutomaticReCAPTCHA(): void {
  console.warn('failed v3')
  CreateReCAPTCHA(uid, reCAPTCHAv2.value, false)
}

function CreateReCAPTCHA(uid: string, key: string, useV3: boolean): void {
  const captchaId = useV3 ? captchaV3Id : captchaV2Id
  let scriptReCaptcha: HTMLScriptElement = document.getElementById(captchaId) as HTMLScriptElement
  if (!useV3 && scriptReCaptcha) {
    return explicitlyRenderReCAPTCHAv2(uid, key)
  }

  //add script to page
  scriptReCaptcha = document.createElement('script')

  scriptReCaptcha.id = captchaId
  scriptReCaptcha.type = 'text/javascript'
  scriptReCaptcha.onerror = function () {
    error.value = translate('captcha.loadingError')
  }
  if (useV3) {
    scriptReCaptcha.src = `${apiUrl}?render=${key}`
  } else {
    window.onloadCaptchaFunction = function () {
      explicitlyRenderReCAPTCHAv2(uid, key)
    }

    scriptReCaptcha.src = `${apiUrl}?onload=onloadCaptchaFunction&render=explicit`
  }
  scriptReCaptcha.async = false

  document.body.append(scriptReCaptcha)
}

function ResetReCAPTCHA(uid: string): void {
  if (typeof grecaptcha !== 'undefined' && uid) grecaptcha.reset(widgetIds[uid])
}

watch(
  () => restaurantStore.captcha.GoogleUseV2,
  async (newState: boolean, oldState: boolean) => {
    if (!oldState && newState) {
      FailedAutomaticReCAPTCHA()
    }
  }
)
</script>

<style lang="scss">
.v-re-captcha-visible {
  display: table;
  margin: 10px auto 10px auto;
}
</style>
