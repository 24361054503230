<i18n>
ru:
  details: Подробнее
ua:
  details: Детальніше
us:
  details: Details
</i18n>

<template>
  <div
    v-if="
      currentDescriptionType === ProductDescriptionType.Text ||
      (!isPopupOrFullPage &&
        currentDescriptionType === ProductDescriptionType.RemovableIngredients &&
        appConfig.VueSettingsPreRun.MenuItemHideRemovableInList)
    "
    class="v-mb-sm"
    :class="[isPopupOrFullPage ? 'v-menu-description-full' : 'v-menu-description']"
  >
    <template v-if="!stringIsNullOrWhitespace(descriptionSanitized)">
      <span :class="[isPopupOrFullPage ? 'v-line-clamp-menu-full' : 'v-line-clamp-menu']">
        <span
          class="v-font-weight-600 v-mr-xxs"
          v-html="translate('itemsPage.productDescription')"
        />
        <span
          itemprop="description"
          v-html="descriptionSanitized"
        />
      </span>
    </template>
  </div>

  <div
    v-else-if="currentDescriptionType === ProductDescriptionType.Ingredients"
    class="v-menu-description-products v-menu-description v-mb-xs"
    itemprop="description"
  >
    <menu-product-description-as-products
      :product-in-list="productInList"
      :show-tooltip="!isSmall"
      :with-preview="isPopupOrFullPage && !!isSmall"
    />
  </div>

  <div
    v-else-if="currentDescriptionType === ProductDescriptionType.RemovableIngredients"
    class="v-menu-description-ingredients v-menu-description v-mb-xs"
    itemprop="description"
  >
    <menu-product-description-as-ingredients :product="productInList" />
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import { useCommon, useWindowSize } from '@arora/common'

import { ProductDescriptionType } from '~api/consts'

const { productInList } = defineProps<{
  productInList: ProductInList
  isPopupOrFullPage?: boolean
}>()

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { isSmall } = useWindowSize()

const descriptionSanitized = computed<string>(() => {
  if (!productInList) return ''

  const optionFromStore = menuStore.SelectedOptionsPerProduct.get(productInList.ID)

  if (
    productInList.UseOptionsDescription &&
    optionFromStore &&
    !stringIsNullOrWhitespace(optionFromStore.ProductDescription)
  ) {
    return sanitize(optionFromStore.ProductDescription)
  }

  return sanitize(productInList.Description)
})

const currentDescriptionType = computed<ProductDescriptionType>(() => {
  return productInList.DescriptionType === ProductDescriptionType.Undefined
    ? ProductDescriptionType.Text
    : productInList.DescriptionType
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-menu-description {
  font-size: 1rem;
  flex-grow: 4;
  .v-title:not(:empty) {
    padding-right: 4px;
  }
}

.v-menu-description-full {
  font-size: 1rem;
  flex-grow: 4;
  color: variables.$BodyTextColor;
  opacity: 0.5;

  &-title {
    font-weight: 600;
  }
}
</style>
