<i18n>
ru:
  cityShort: 'г. {city}'
  inHallWarning: >-
    'Закажите В зале, если вы находитесь в одном из наших заведений.
    Выберите номер, который видите на своем столике.'
  noTables: Нет доступных столов в зале
  noTableSelected: Выберите стол
  noTerminalSelected: Выберите точку
  restaurant: Ресторан
  table: Стол
  tableNum: 'Стол №{num}'
ua:
  cityShort: 'м. {city}'
  inHallWarning: >-
    'Замовте в залі, якщо ви знаходитесь в одному з наших закладів.
    Виберіть номер, який бачите на своєму столику.'
  noTables: Немає доступних столів в залі
  noTableSelected: Виберіть стiл
  noTerminalSelected: Виберіть точку
  restaurant: Ресторан
  table: Стiл
  tableNum: 'Стiл №{num}'
us:
  cityShort: '{city} city'
  inHallWarning: >-
    'Order In restaurant hall, if you are in one of our restaurants.
    Choose the number you see on your table.'
  noTables: There are no tables available in the hall
  noTableSelected: Pick a table
  noTerminalSelected: Pick a restaurant
  restaurant: Restaurant
  table: Table
  tableNum: 'Table #{num}'
</i18n>

<template>
  <div class="v-in-hall-table-select-wrapper">
    <div
      v-if="terminals.length > 0"
      class="v-in-hall-terminal-select"
    >
      <arora-select
        required
        :default-option="emptyInHallTerminalId"
        :default-option-text="translate('inHallTableSelect.noTerminalSelected')"
        :default-option-value="Guid.Empty"
        :label="translate('inHallTableSelect.restaurant')"
        :options="terminals.map((item) => item.ID)"
        v-model:selected="inHallTerminalId"
        @change="(event: Event) => onChangeSelfServiceTerminalSelect(event)"
      >
        <template #index="options: { index: number }">
          <option
            :value="terminals[options.index].ID"
            v-html="
              showCity
                ? `${translate('inHallTableSelect.cityShort', { city: terminals[options.index].City })}, ${sanitize(
                    terminals[options.index].Address
                  )}`
                : sanitize(terminals[options.index].Address)
            "
          />
        </template>
      </arora-select>
    </div>

    <div
      v-if="hallTablesNumberMax > 0"
      class="v-in-hall-table-select"
    >
      <arora-select
        default-option
        required
        :default-option-text="translate('inHallTableSelect.noTableSelected')"
        :default-option-value="0"
        :label="translate('inHallTableSelect.table')"
        :options="Array.from({ length: hallTablesNumberMax }, (_v, i) => i + 1)"
        v-model:selected="inHallTableNumber"
      >
        <template #index="options: { index: number }">
          <option
            :value="options.index + 1"
            v-html="translate('inHallTableSelect.tableNum', { num: options.index + 1 })"
          />
        </template>
      </arora-select>
    </div>
    <div
      v-else
      class="v-in-hall-table-select"
    >
      <span
        class="v-in-hall-table-select-no-tables v-text-center"
        v-html="translate('inHallTableSelect.noTables')"
      />
    </div>

    <div class="v-in-hall-table-select-full">
      <cart-old-comment v-if="appConfig.RestaurantSettingsPreRun.OneStageCart" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'

import type { GUID } from '@arora/common'

import { Guid } from '~api/consts'

const addressStore = useAddressStore()
const clientStore = useClientStore()

const { sanitize, translate } = useI18nSanitized()
const appConfig = useAppConfig()

onMounted(async () => {
  if (import.meta.client) {
    await Promise.all([addressStore.initTerminals(), clientStore.initClientState()])
  }
})

const { getTerminalById, terminal } = useAddressSelector()
const hallTablesNumberMax = computed<number>(() => {
  return terminal.value?.InHallSetting?.HallTablesNumber ?? 0
})
const terminals = computed<Terminal[]>(() =>
  (addressStore.Terminals?.data ?? [])
    .filter((t: Terminal) => t.Active && t.InHallSetting?.HallTablesNumber > 0)
    .sort((a: Terminal, b: Terminal) => {
      return a.Address > b.Address ? 1 : -1 //sort alphabetically
    })
)
const showCity = computed<boolean>(() => appConfig.VueSettingsPreRun.ShowCityInTerminalSelect ?? false)
const inHallTerminalId = computed<GUID>(
  () => clientStore.ClientState?.data?.SelectedInHall?.TerminalID ?? Guid.Empty
)

const inHallTableNumber = computed<number>({
  get: () => clientStore.ClientState?.data?.SelectedInHall?.TableNumber ?? 0,
  set: (value) => {
    const tableNumber = clientStore.ClientState?.data?.SelectedInHall?.TableNumber ?? 0
    if (tableNumber === 0 && appConfig.VueSettingsPreRun.InHallShowPopup) {
      clientStore.showPopupAboutInHall(appConfig.VueSettingsPreRun.InHallAlwaysShowPopup ?? false)
    }

    clientStore.updateDeliveryAddressSingle({
      tableNumber: value ?? 0
    })
  }
})

const emptyInHallTerminalId = computed<boolean>(() => Guid.IsNullOrEmpty(inHallTerminalId.value))

async function onChangeSelfServiceTerminalSelect(event: Event): Promise<void> {
  await clientStore.onChangeSelfServiceTerminal(
    getTerminalById((event.target as HTMLInputElement).value as GUID)
  )
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-in-hall-table-select-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
}

.v-in-hall-terminal-select {
  flex: 0 0 calc(65% - 5px);
  width: calc(65% - 5px);

  @include mixins.sm {
    flex: 0 0 100%;
    width: 100%;
  }
}

.v-in-hall-table-select {
  flex: 0 0 calc(35% - 5px);
  width: calc(35% - 5px);

  @include mixins.sm {
    flex: 0 0 100%;
    width: 100%;
  }
}

.v-in-hall-table-select-full {
  flex: 0 0 100%;
  width: 100%;
}

.v-in-hall-table-select-no-tables {
  color: variables.$BodyTextColorLight;
}
</style>
