<i18n>
ru:
  pointsAvailable: Доступно к списанию
  errorPointsInsufficient: Баланса баллов недостаточно
  errorPointsNotAllowed: Баллы недоступны к списыванию
  yourBalance: Ваш баланс
ua:
  pointsAvailable: Доступно до списання
  errorPointsInsufficient: Недостатньо балів на балансі
  errorPointsNotAllowed: Бали недоступні до списування
  yourBalance: Ваш баланс
us:
  pointsAvailable: Points available
  errorPointsInsufficient: Insufficient points
  errorPointsNotAllowed: You are not allowed to pay with loyalty points
  yourBalance: Your balance is
</i18n>

<template>
  <div
    v-if="accountStore.isLoggedIn && loaded && enabled && !hidden"
    class="v-partial-pay-wrapper"
  >
    <span
      v-if="!allowed"
      class="v-error-color"
      v-html="translate('partialPay.errorPointsNotAllowed')"
    />
    <span
      v-else-if="pointsAvailable === 0"
      class="v-error-color"
      v-html="translate('partialPay.errorPointsInsufficient')"
    />
    <template v-else>
      <div
        v-if="customActionText.length > 0"
        v-html="sanitize(customActionText)"
      />
      <div
        v-if="message.length > 0"
        v-html="sanitize(message)"
      />
    </template>

    <div class="v-partial-pay-wrapper">
      <div class="v-user-balance">
        <span v-html="translate('partialPay.yourBalance')" />
        <common-currency
          show-points
          :amount="pointsTotal"
        />
      </div>
      <div class="v-partial-pay-available">
        <span v-html="translate('partialPay.pointsAvailable')" />
        <common-currency
          show-points
          :amount="pointsAvailable"
        />
      </div>
    </div>
    <div class="v-partial-pay-slider-wrapper">
      <span
        class="v-partial-pay-slider-digit"
        v-html="'0'"
      />
      <span class="v-partial-pay-slider">
        <arora-input-range
          :disabled="!accountStore.isLoggedIn || pointsAvailable === 0 || !allowed"
          :label="`${translate('partialPay.yourBalance')} ${pointsAvailable}`"
          :max="pointsAvailable"
          :original-value="partialPay"
          :update-val="sliderChanged"
        />
      </span>
      <span
        class="v-partial-pay-slider-digit v-partial-pay-slider-digit-selected"
        v-html="numeric(pointsAvailable, 'currency')"
      />
      <input
        class="v-partial-pay-slider-total-left v-form-control"
        :class="{
          disabled: !accountStore.isLoggedIn || pointsAvailable === 0 || !allowed
        }"
        v-model="partialPayInput"
      />
      <span class="v-partial-pay-slider-total-right v-form-control disabled">
        <common-currency show-points />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PartialPayState } from '~types/clientStore'

const { numeric, sanitize, translate } = useI18nSanitized()
const clientStore = useClientStore()
const accountStore = useAccountStore()

const loaded = computed<boolean>(() => !!clientStore.ClientState.data?.PartialPayState)

const partialPay = ref<number>(
  clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0
)
const valueTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const partialPayInput = computed<string>({
  get: () => (Number.isNaN(partialPay.value) ? '0' : partialPay.value.toString()),
  set: (value) => {
    const parsedValue = value.length > 0 ? Number.parseInt(value) : 0
    sliderChanged(Number.isNaN(parsedValue) ? 0 : parsedValue)
  }
})

function sliderChanged(value: number): void {
  partialPay.value = value
  if (valueTimeout.value) clearTimeout(valueTimeout.value)
  valueTimeout.value = setTimeout(() => {
    accountStore.updatePartialPay(value)
  }, 400)
}
const appConfig = useAppConfig()

const enabled = computed<boolean>(
  () => clientStore.ClientState.data?.PartialPayState?.PartialPayEnabled ?? false
)
const allowed = computed<boolean>(
  () => clientStore.ClientState.data?.PartialPayState?.PartialPayAllowed ?? false
)
const hidden = computed<boolean>(
  () => !allowed.value && appConfig.VueSettingsPreRun.TotalSummaryPartialPayHideIfUnavailable
)

const pointsAvailable = computed<number>(
  () => clientStore.ClientState.data?.PartialPayState?.MaxBoundary ?? 0
)
const pointsTotal = computed<number>(
  () => clientStore.ClientState.data?.PartialPayState?.TotalPoints ?? 0
)

const customActionText = computed<string>(
  () => clientStore.ClientState.data?.PartialPayState?.CustomActionText ?? ''
)
const message = computed<string>(() => clientStore.ClientState.data?.PartialPayState?.Message ?? '')

watch(
  () => clientStore.ClientState.data?.PartialPayState ?? null,
  async (newContent: PartialPayState | null) => {
    if (newContent) {
      partialPay.value = clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-partial-pay-wrapper {
  font-size: variables.$TextSizeMain;
}

.v-user-balance {
  display: flex;
  gap: 5px;

  .v-amount {
    color: variables.$PrimaryBackgroundColor;
  }
}

.v-partial-pay-available {
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0;
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.v-partial-pay-slider-wrapper {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  align-items: center;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .v-partial-pay-slider {
    flex: 0 0 70%;
    max-width: 70%;
    padding: 0 15px;

    @include mixins.md {
      flex: 0 0 55%;
      max-width: 55%;
    }

    @include mixins.xs {
      flex: 0 0 45%;
      max-width: 45%;
      padding: 10px;
    }
  }

  .v-partial-pay-slider-digit {
    flex: 0 0 7.5%;
    max-width: 7.5%;

    &-selected {
      @include mixins.md {
        flex: 0 0 12.5%;
        max-width: 12.5%;
      }
    }
  }

  .v-partial-pay-slider-total-left {
    flex: 0 0 10%;
    max-width: 10%;
    border: variables.$BorderWidth solid variables.$BorderColor;
    border-left: 0;
    box-shadow: variables.$InputShadow;
    padding: 10px;
    border-radius: variables.$BorderRadius 0 0 variables.$BorderRadius;
    font-size: 13px;
    height: 38px;
    text-align: right;

    @include mixins.md {
      flex: 0 0 15%;
      max-width: 15%;
    }

    @include mixins.xs {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .v-partial-pay-slider-total-right {
    flex: 0 0 5%;
    max-width: 5%;
    border: variables.$BorderWidth solid variables.$BorderColor;
    border-right: 0;
    box-shadow: variables.$InputShadow;
    padding: 10px;
    border-radius: 0 variables.$BorderRadius variables.$BorderRadius 0;
    font-size: 13px;
    height: 36px;
    display: flex;
    align-items: center;

    @include mixins.md {
      flex: 0 0 10%;
      max-width: 10%;
    }

    @include mixins.xs {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
}
</style>
