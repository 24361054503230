import type { ProductDescriptionType, ProductMeasureType } from '~api/consts'
import type { Restaurant } from '~types/addressStore'
import type { Period, Slot } from '~types/clientStore'
import type { RemovableIngredient } from '~types/menuStore'

import type { CanBeDisabled, GUID, HasID, ServerError } from '@arora/common'

export type StoreState<T> = {
  state: 'error' | 'loading' | 'success' | null
  data: T | null
  error: ServerError | null
}

export type CJE = {
  CurrentRestaurant: Restaurant
  restaurantId: GUID
  ssid: GUID
  version: 'desktop' | 'mobile'
  currentGroupID: GUID | undefined
}

export type Webim = {
  accountName: string
  domain: string
}

export type ImageInfo = {
  Domain: string | undefined
  Path: string
}

export type CartModifier = HasID & {
  Count: number
  ModifierID: GUID
  Name: string
  ProductID: GUID
}

type ModifiersSetting = HasID &
  HasSortWeight & {
    Name: string
    Recommended: boolean
    ValueDefault: number
    ValueMax: number
    ValueMin: number
  }

export interface HaveCaloricValues {
  CarbohydrateAmount: number
  EnergyAmount: number
  FatAmount: number
  ProteinsAmount: number

  Weight: number
}

export type ProductCommon = HaveCaloricValues &
  HasID &
  HasActive & {
    InStopList: boolean
    Name: string
    PersonalProductMessage?: string | null
    Description?: string
    Price: number
  }

type LightNomenclatureImages = {
  //images
  ActiveOptionURL: ImageInfo
  CombineImageURL: ImageInfo
  CombineMobileImageURL: ImageInfo
  MobileActiveOptionURL: ImageInfo
  MobileNotActiveOptionURL: ImageInfo
  NormalImageURL: ImageInfo
  NotActiveOptionURL: ImageInfo
  ProductMobileURL: ImageInfo
  SmallImageURL: ImageInfo
  ThumbnailImageURL: ImageInfo
}

export type ProductImages = {
  CombineMobileImage: ImageInfo
  FullImage: ImageInfo
  NormalImage: ImageInfo
  OriginalImage: ImageInfo
  ProductMobileImage: ImageInfo
  SmallImage: ImageInfo
  ThumbnailImage: ImageInfo
}

export type Measurable = {
  Quantity: number
  Measure: ProductMeasureType
}

export type ProductOption = HasID & {
  IsDefault: boolean
  Name: string
  Description: string
}

export type ProductInfo = ProductCommon &
  LightNomenclatureImages &
  Measurable &
  HasSortWeight & {
    CanOrderWithDelivery: boolean
    CanOrderWithPickup: boolean
    DescriptionType: ProductDescriptionType
    GroupID: GUID
    HasActiveOptions: boolean
    HasModifiers: boolean
    IsCombine: boolean
    MinCountModifiers: number
    MaxCountModifiers: number
    ModifiersID: GUID[]
    ModifiersSettings: ModifiersSetting[]
    PriceInPoints: number
    PriceToPay: number
    RemovableIngredients: RemovableIngredient[]
    ShowProductInfo: number
    Slots: Slot[]
    Tags: string[]
    showProductInfo: number
    //options
    HasOptions: boolean
    Options: Record<GUID, ProductOption>
    OptionsID: GUID[]
  }

export type ProductDiscount = ProductCommon &
  ProductImages &
  Measurable &
  HasSortWeight & {
    CanOrderWithDelivery: boolean
    CanOrderWithPickup: boolean
    GroupID: GUID
    HasActiveOptions: boolean
    HasModifiers: boolean
    IsCombine: boolean
    MinCountModifiers: number
    MaxCountModifiers: number
    ModifiersID: GUID[]
    ModifiersSettings: ModifiersSetting[]
    PriceInPoints: number
    PriceToPay: number
    ShowProductInfo: number
    Slots: Slot[]
    Tags: string[]
    showProductInfo: number
    //options
    HasOptions: boolean
    Options: ProductOption[]
    FirstOption: ProductCommon
  }

export type ChipsSettings<T> = {
  ID: T
  Name: string
  Link?: string
}
export type TabsSettings<T> = {
  ID: T
  Name: string
  Link?: string
}

export type QR = {
  payload64: string
  format: string
}

export interface HasActive {
  Active: boolean
}

export interface HasSortWeight {
  SortWeight: number
}

export type HasCaptcha = {
  smartcaptcha: string | null
  recaptcha: string | null
  passedV3: boolean
}

export type PossibleOptions = {
  text: string
  value: number
  period?: Period
}

export type TypeaheadOptions<T> = HasID &
  CanBeDisabled & {
    title: string
    subtitle?: string
    description?: string
    onClick: () => void
    image?: ImageInfo
    item: T
  }

export type EmitterEvent =
  | 'v-captcha-hidden'
  | 'v-captcha-visible'
  | 'v-discount-timer'
  | 'v-discount-timer-single'
  | 'v-order-cancel'
  | 'v-reset-captcha'
  | 'v-time-refresh'
  | 'ymaps3-loaded'

export type NumericType = 'currency' | 'currencyCompact' | 'int' | 'nutritional' | 'nutritionalFixed'

export type SourceType =
  | 'backoffice'
  | 'mobile-app'
  | 'robot'
  | 'site-android'
  | 'site-desktop'
  | 'site-ios'
  | 'site-mobile'
  | 'vk-bridge'

export type TextWithHighlight = {
  text: string
  hl: [number, number][]
}

export type GoogleRecaptcha = {
  execute: (key: string, options: { action: string }) => Promise<string>
  ready: (callback: () => void) => void
  reset: (widgetId: number) => void
  render: (
    container: string,
    options: {
      sitekey: string | null
      theme: 'dark' | 'light'
      callback: (response: string) => void
    }
  ) => number
}

export type SmartCaptcha = {
  execute: () => void
  subscribe: (uid: string, event: string, callback: (token: string) => void) => void
  reset: (uid: string) => void
  render: (container: string, options: { sitekey: string | null; invisible: boolean }) => string
}

export class IntervalTimer {
  public paused = false
  private readonly _callback: () => void
  private readonly _delay: number
  private callbackStartTime = 0

  private remaining = 0

  private timerId: ReturnType<typeof setInterval> | undefined = undefined

  constructor(callback: () => void, delay: number) {
    this._callback = callback
    this._delay = delay
  }
  clear(): void {
    clearInterval(this.timerId)
  }
  pause(): void {
    if (!this.paused) {
      this.clear()
      this.remaining = Date.now() - this.callbackStartTime
      this.paused = true
    }
  }

  resume(): void {
    if (this.paused) {
      if (this.remaining) {
        setTimeout(() => {
          this.run()
          this.paused = false
          this.start()
        }, this.remaining)
      } else {
        this.paused = false
        this.start()
      }
    }
  }

  run(): void {
    this.callbackStartTime = Date.now()
    this._callback()
  }

  start(): void {
    this.clear()
    this.timerId = setInterval(() => {
      this.run()
    }, this._delay)
  }
}
