<template>
  <div
    class="v-popup-head v-title v-justify-content-center v-font-weight-600"
    :data-test-id="
      stringIsNullOrWhitespace(dataTestId)
        ? `message-popup-${type}`
        : `message-popup-${type}-${dataTestId}`
    "
  >
    <div class="v-error-color">
      <span
        v-html="
          stringIsNullOrWhitespace(title)
            ? translate(
                type === 'exception' ? 'messagePopup.exceptionTitle' : 'messagePopup.internalTitle'
              )
            : sanitize(title)
        "
      />
    </div>
  </div>

  <div class="v-popup-body">
    <div
      class="v-message-container"
      data-test-id="message-popup-text"
    >
      <div v-html="translate('messagePopup.exceptionSubtitle')" />
      <arora-summary-details
        in-element
        :title="translate('messagePopup.exceptionDetails')"
      >
        <code v-html="sanitize(message)" />
      </arora-summary-details>
    </div>
    <div class="v-popup-buttons-block">
      <arora-button
        class-name="v-btn-message v-btn-text v-no-underline v-font-weight-500"
        ignore-settings
        :label="translate('messagePopup.ok')"
        data-test-id="message-popup-button"
        @click="close"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MessagePopupProps } from '~types/props'

import { useCommon } from '@arora/common'

defineProps<MessagePopupProps>()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const popupStore = usePopupStore()

function close(): void {
  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-content {
  .v-popup-body {
    .v-popup-buttons-block {
      text-align: right;
      margin-top: 20px;
      justify-content: flex-end;

      .v-btn-message {
        color: variables.$BodyTextColorLight;
        font-size: 1.1rem;
      }
    }
  }
}

.v-message-container {
  word-wrap: anywhere;
  gap: 10px;

  &--success,
  &--warning {
    .v-message-container--icon {
      color: variables.$PrimaryBackgroundColor;
    }
  }

  &--error {
    .v-message-container--icon {
      color: variables.$ErrorColor;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }
}
</style>
