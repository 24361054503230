<i18n>
ru:
  success: Скопировано!
  error: Не удалось скопировать
ua:
  success: Скопійовано!
  error: Не вдалося скопіювати
us:
  success: Copied!
  error: Failed to copy
</i18n>

<template>
  <common-popover-dropdown
    :class="classNameContainer"
    :control-shown="{ control: true, state: isClicked }"
    placement="top"
  >
    <template #hoverable>
      <span>
        <icon-general-copy
          :class="classNameContent"
          @click="handleCopyContent"
        />
      </span>
    </template>
    <template #content>
      <span v-html="translate(isCopySuccess ? 'copyContent.success' : 'copyContent.error')" />
    </template>
  </common-popover-dropdown>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

const {
  classNameContainer = '',
  classNameContent = '',
  content
} = defineProps<{
  content: string
  classNameContainer?: string
  classNameContent?: string
}>()

const { translate } = useI18nSanitized()
const { copyContent } = useCommon()

const isClicked = ref<boolean>(false)
const isCopySuccess = ref<boolean>(false)

async function handleCopyContent(): Promise<void> {
  if (isClicked.value) return

  isCopySuccess.value = await copyContent(content)
  isClicked.value = true

  setTimeout(() => {
    isClicked.value = false
  }, 1500)
}
</script>
