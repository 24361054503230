<i18n>
ru:
  addToCart: В корзину
  errorUnknown: Неизвестная ошибка!
  notAll: >-
    Упс! Некоторые товары недоступны для повторного заказа. Остальные товары уже
    ждут Вашего заказа в корзине
  order: 'Заказ от {date}'
  orderAmount: 'Сумма заказа:'
  orderNum: 'Заказ №{num} от {date}'
  success: Ваши товары уже ждут Вашего заказа в корзине
  title: Ваш заказ
ua:
  addToCart: В кошик
  errorUnknown: Невідома помилка!
  notAll: >-
    Ой! Деякі товари недоступні для повторного замовлення. Решта товарів вже
    чекають на Ваше замовлення у кошику
  order: 'Замовлення від {date}'
  orderAmount: 'Сума замовлення:'
  orderNum: 'Замовлення №{num} від {date}'
  success: Ваші товари вже чекають вашого замовлення в кошику
  title: Ваше замовлення
us:
  addToCart: Add to Cart
  errorUnknown: Unknown error!
  notAll: >-
    Oops! Some items are unavailable for re-order. The rest of the items are
    already waiting for your order in the cart
  order: 'Order from {date}'
  orderAmount: 'Order amount:'
  orderNum: 'Order #{num} from {date}'
  success: Your items are already waiting for your order in the cart
  title: Your order
</i18n>

<template>
  <div class="v-account-order-repeat">
    <div
      class="v-popup-head v-title v-account-order-repeat__title v-font-weight-600 v-mb-sm"
      v-html="translate('orderRepeatPopup.title')"
    />
    <div
      class="v-account-order-repeat__subtitle v-mb-sm"
      v-html="
        order.Number && order.Number !== '0'
          ? translate('orderRepeatPopup.orderNum', {
              date: dateTime(order.Created * 1000, 'short'),
              num: order.Number
            })
          : translate('orderRepeatPopup.order', {
              date: dateTime(order.Created * 1000, 'short')
            })
      "
    />

    <div class="v-mb-sm">
      <div
        v-for="item in order.Items"
        :key="`${sanitize(item.ProductID)}-${sanitize(item.OptionID)}`"
        class="v-account-order-repeat-item v-row"
      >
        <div class="v-col-2">
          <arora-nuxt-image
            :alt="item.Name"
            :image="item.SmallImage"
            image-type="ProductSmall"
          />
        </div>
        <div class="v-col-10 v-d-flex v-flex-column v-justify-content-between">
          <div
            class="v-account-order-repeat-item__title v-mb-xs"
            v-html="item.Name"
          />
          <div
            v-if="appConfig.VueSettingsPreRun.OrderCompleteShowProductDesc && item.Description"
            class="v-account-order-repeat-item__subtitle"
            v-html="sanitize(item.Description)"
          />
          <div
            v-if="item.HasModifiers"
            class="v-account-order-repeat-item__subtitle"
          >
            <span
              v-for="(count, modIndex) in item.Modifiers"
              :key="`mod-${modIndex}`"
              v-html="
                translate('orderCompleteItems.modString', {
                  count: count,
                  mod: item.ModifiersNames[modIndex]
                })
              "
            />
          </div>

          <div class="v-d-flex v-justify-content-between">
            <div class="v-d-flex v-flex-row-no-wrap v-account-order-repeat-item__prices">
              <div class="v-mr-xs">
                <common-currency
                  v-if="item.PriceInPoints > 0"
                  show-points
                  :amount="item.PriceInPoints"
                />
                <common-currency
                  v-else
                  :amount="item.PriceFullModified"
                />
              </div>
              <common-currency
                v-if="item.PriceFullModified !== item.PriceFull"
                strikethrough
                :amount="item.PriceFull"
              />
            </div>
            <div
              class="v-account-order-repeat-item__amount"
              v-html="translate('orderDetailsPopup.count', { num: item.Count })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="v-row v-align-items-center v-popup-buttons">
      <div class="v-col-6">
        <span
          class="v-mr-xs"
          v-html="translate('orderRepeatPopup.orderAmount')"
        />

        <common-currency :amount="order.SumTotal" />
      </div>
      <div class="v-col-6 v-d-flex v-align-items-center v-justify-content-end">
        <arora-button
          :label="translate('orderRepeatPopup.addToCart')"
          @click="async () => await repeatOrder(order.ID, true)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderInfo } from '~types/clientStore'

defineProps<{
  order: OrderInfo
}>()
const { repeatOrder } = useAccount()

const { dateTime, sanitize, translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-account-order-repeat {
  &__title {
    font-size: variables.$TextSizeH3;
    font-weight: 500;
  }
}

.v-account-order-repeat-item {
  padding: 16px 0;
  border-bottom: 1px solid variables.$BorderColor;

  &__title {
    font-size: 1.1rem;
  }

  &__subtitle {
    color: variables.$BodyTextColorLight;
  }

  &__prices {
    font-size: variables.$TextSizeMain;
    max-height: variables.$TextSizeMain;
  }

  &__amount {
    background: variables.$SecondaryBackgroundColor;
    color: variables.$SecondaryColor;
    padding: 5px 10px;
    border-radius: variables.$BorderRadiusInput;
    font-size: 1.1rem;
    font-weight: 500;

    pointer-events: none;
  }
}
</style>
