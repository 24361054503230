<template>
  <section
    v-if="pageStore.ActionsOnMain"
    id="v-banners-main-page__Minas-Anor"
  >
    <template v-if="pageStore.Actions.state === 'success'">
      <arora-swiper-slider
        v-if="isDesktopVersion"
        class-name="v-banner-main-page-item__Minas-Anor"
        :autoplay-delay="appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000"
        centered
        :items="pageStore.ActionsOnMain"
        :loop="pageStore.ActionsOnMain.length > 2"
        pagination-type="dynamic-bullets"
        :space-between="30"
        max-items="auto"
      >
        <template #item="item">
          <home-banners-minas-anor-single :action-banner="item" />
        </template>
      </arora-swiper-slider>
      <arora-carousel
        v-else
        :items="pageStore.ActionsOnMain"
        :loop="pageStore.ActionsOnMain.length > 2"
        :max-items="7.15"
        :min-items="1.15"
        show-part-of-next-slide
        :autoplay-delay="appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000"
        pagination-type="none"
        :space-between="15"
        snap-align="start"
      >
        <template #item="item">
          <home-banners-minas-anor-single :action-banner="item" />
        </template>
      </arora-carousel>
    </template>
    <common-skeleton
      v-else
      :height="175"
      :loaders-in-row="5"
      :space-between="20"
      :width="175"
    />
  </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

const appConfig = useAppConfig()
const { isDesktopVersion } = useWindowSize()

const pageStore = usePageStore()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#v-banners-main-page__Minas-Anor {
  margin-bottom: 60px;

  @include mixins.sm {
    margin-bottom: 20px;
  }

  .swiper-slide {
    position: relative;
    @include mixins.trans;
    max-height: 60%;
    width: 60%;
    transform: scale(0.9);

    @include mixins.lg {
      transform: scale(1);
      width: 40%;
      max-height: initial;
    }

    img {
      width: 100%;
      overflow: hidden;
      border-radius: variables.$BorderRadius;
      box-shadow: variables.$CardShadow;
    }

    &.swiper-slide-active {
      transform: scale(1);
      max-height: initial;
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      position: relative;
      max-height: initial;
      &:before {
        position: absolute;
        z-index: 5;
        content: '';
        background: rgba(244, 244, 244, 0.3);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include mixins.md {
          display: none;
        }
      }
    }
  }

  .swiper-pagination-bullets {
    bottom: -12px;
    .swiper-pagination-bullet-active {
      background: variables.$SecondaryBackgroundColor;
    }
  }

  .v-banner-info {
    box-shadow: variables.$BannerShadow;
    border-radius: variables.$BorderRadius;
    position: relative;
    display: flex;
    @include mixins.lg {
      border: 2px solid variables.$SecondaryBackgroundColor;
      padding: 6px;
      display: flex;
    }
  }

  .carousel {
    margin: 0 1rem;
  }
}
</style>
