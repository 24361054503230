<i18n>
ru:
  caption: Наверх
ua:
  caption: Нагору
us:
  caption: Up
</i18n>

<template>
  <div
    :id="uid"
    class="v-scroll-to-the-top v-pointer"
    :aria-label="translate('scrollToTop.caption')"
    @click="scrollToTheTop"
  >
    <icon-general-arrow-top class="v-scroll-to-the-top__icon" />
    <span
      class="v-font-weight-500"
      v-html="translate('scrollToTop.caption')"
    />
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

const uid = useId()
const { translate } = useI18nSanitized()

function scrollToTheTop(): void {
  window.scrollTo({
    behavior: 'smooth',
    top: 0
  })
}

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('scroll', handleScroll)
  }
  handleScroll()
})

onUnmounted(() => {
  if (import.meta.client) {
    window.removeEventListener('scroll', handleScroll)
  }
})
let previousScroll = import.meta.client ? window.scrollY : 0
function handleScroll(): void {
  if (!import.meta.client) return

  if (window.scrollY <= 100) {
    //for overscroll
    gsap.to(`#${uid}`, {
      duration: 0.2,
      ease: 'sine.inOut',
      top: -150
    })
  } else {
    const percentScroll = window.scrollY / document.body.offsetHeight

    const header = document.querySelectorAll('.v-header-groups')[0]

    if (previousScroll < window.scrollY || Math.round(percentScroll * 100) <= 25) {
      gsap.to(`#${uid}`, {
        duration: 0.2,
        ease: 'sine.inOut',
        top: -150
      })
    } else if (previousScroll > window.scrollY) {
      gsap.to(`#${uid}`, {
        duration: 0.2,
        ease: 'sine.inOut',
        top: header.clientHeight + 10
      })
    }
  }

  previousScroll = window.scrollY
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-scroll-to-the-top {
  position: fixed;
  top: -10%;
  left: 0;
  right: 0;
  margin: 0 auto;

  display: inline-flex;
  padding: 8px 26px;
  align-items: center;
  gap: 4px;

  max-width: fit-content;

  z-index: 497; //behind popup and header

  border-radius: variables.$BorderRadiusInput;
  border: 1px solid variables.$BorderColor;
  background: variables.$BodyElementsBackgroundOpaq90;
  color: variables.$BodyTextColor;
  box-shadow: variables.$FloatShadow;
  font-size: variables.$TextSizeMain;
  user-select: none;

  &__icon {
    width: 24px;
    height: 24px;
    fill: none;

    g > path {
      stroke: variables.$BodyTextColor;
    }
  }
}
</style>
