<template>
  <!--Belfalas-->
  <div
    v-if="appConfig.VueSettingsPreRun.DiscountTimerLayout === 'Belfalas'"
    class="Belfalas v-mb-sm"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div
        class="v-discount-bg-title"
        v-html="translate('discountTimersPage.title')"
      />
      <div class="v-discount-bg-container">
        <arora-swiper-slider
          :autoplay-delay="3000"
          :items="discountsData"
          :max-items="appConfig.VueSettingsPreRun.DiscountTimerProductsMax"
          :show-part-of-next-slide="appConfig.VueSettingsPreRun.DiscountTimerShowNextSlide"
          :space-between="30"
        >
          <template #item="discount: CurrentDiscount">
            <div
              class="v-item-discount-wrapper"
              data-test-id="discount-timer-item"
            >
              <div class="v-image-discount-absolute-wrapper">
                <div class="v-image">
                  <arora-nuxt-image
                    :alt="discount.ActionProductData.Name"
                    :image="discount.ActionProductData.SmallImage"
                    image-type="ProductSmall"
                  />
                  <div
                    class="v-timer-block"
                    v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
                  >
                    <common-countdown :expires="discount.TimeEndSecTS" />
                  </div>
                </div>
              </div>
              <div class="v-discount-timers-summary-bottom-buy">
                <div
                  class="v-title"
                  v-html="sanitize(discount.ActionProductData.Name)"
                />
                <div class="v-price-block">
                  <arora-button
                    :label="translate('addToCartButton.addToCart')"
                    data-test-id="discount-timer-add-to-cart"
                    @click="
                      async () =>
                        await addToBasket(
                          discount.ActionProductData.ID,
                          discount.ID,
                          discount.GroupID,
                          discount.ActionProductData.Price,
                          1,
                          discount.ID
                        )
                    "
                  >
                    <span class="v-price v-old">
                      <common-currency
                        strikethrough
                        :amount="
                          discount.CurrentProductData.HasOptions &&
                          discount.CurrentProductOptionID !== Guid.Empty
                            ? discount.CurrentProductData.Price +
                              discount.ProductsInfo[discount.CurrentProductOptionID].Price
                            : discount.CurrentProductData.Price
                        "
                      />
                    </span>
                    <span class="v-price">
                      <common-currency :amount="discount.ActionProductData.Price" />
                    </span>
                  </arora-button>
                </div>
              </div>
            </div>
          </template>
        </arora-swiper-slider>
      </div>
    </div>
  </div>
  <!--end Belfalas-->

  <!-- Carchost -->
  <div
    v-else-if="appConfig.VueSettingsPreRun.DiscountTimerLayout === 'Carchost'"
    class="Carchost v-mb-sm"
    :style="discountTimerBackground"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div class="v-discount-timer-aggregator">
        <div
          class="v-discount-timer-title v-title v-mb-sm"
          v-html="translate('discountTimersPage.title')"
        />
        <div
          class="v-discount-timer-description v-mb-sm"
          v-html="translate('discountTimersPage.description')"
        />
      </div>

      <arora-swiper-slider
        :autoplay-delay="3000"
        :items="discountsData"
        :max-items="appConfig.VueSettingsPreRun.DiscountTimerProductsMax"
        :show-part-of-next-slide="appConfig.VueSettingsPreRun.DiscountTimerShowNextSlide"
      >
        <template #item="discount: CurrentDiscount">
          <div
            class="v-item-discount-wrapper"
            data-test-id="discount-timer-item"
          >
            <div class="v-image-absolute">
              <div class="v-image">
                <arora-nuxt-image
                  :alt="discount.ActionProductData.Name"
                  :image="discount.ActionProductData.SmallImage"
                  image-type="ProductSmall"
                />
              </div>
            </div>
            <div class="v-discount-timers-summary-right">
              <div
                class="v-title v-mb-sm"
                v-html="sanitize(discount.ActionProductData.Name)"
              />
              <div
                class="v-timer-block v-mb-sm"
                v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
              >
                <div
                  class="v-timer-name v-mb-sm"
                  v-html="translate('discountTimersPage.timerName')"
                />
                <common-countdown :expires="discount.TimeEndSecTS" />
              </div>
              <div class="v-price-block">
                <arora-button
                  :label="translate('addToCartButton.addToCart')"
                  data-test-id="discount-timer-add-to-cart"
                  @click="
                    async () =>
                      await await addToBasket(
                        discount.ActionProductData.ID,
                        discount.ID,
                        discount.GroupID,
                        discount.ActionProductData.Price,
                        1,
                        discount.ID
                      )
                  "
                >
                  <span class="v-price v-old">
                    <common-currency
                      strikethrough
                      :amount="
                        discount.CurrentProductData.HasOptions &&
                        discount.CurrentProductOptionID !== Guid.Empty
                          ? discount.CurrentProductData.Price +
                            discount.ProductsInfo[discount.CurrentProductOptionID].Price
                          : discount.CurrentProductData.Price
                      "
                    />
                  </span>
                  <span class="v-price">
                    <common-currency :amount="discount.ActionProductData.Price" />
                  </span>
                </arora-button>
              </div>
            </div>
          </div>
        </template>
      </arora-swiper-slider>
    </div>
  </div>
  <!-- end Carchost -->

  <!-- Dimholt -->
  <div
    v-else-if="appConfig.VueSettingsPreRun.DiscountTimerLayout === 'Dimholt'"
    class="Dimholt v-mb-sm"
    data-test-id="discount-timer"
  >
    <div class="v-container">
      <div class="v-discount-timer-aggregator">
        <div
          class="v-discount-timer-title v-title v-mb-sm"
          v-html="translate('discountTimersPage.title')"
        />
        <div
          class="v-discount-timer-description v-mb-sm"
          v-html="translate('discountTimersPage.description')"
        />
      </div>

      <arora-swiper-slider
        :autoplay-delay="3000"
        :items="discountsData"
        :max-items="appConfig.VueSettingsPreRun.DiscountTimerProductsMax"
        :show-part-of-next-slide="appConfig.VueSettingsPreRun.DiscountTimerShowNextSlide"
      >
        <template #item="discount: CurrentDiscount">
          <div
            class="v-item-discount-wrapper"
            data-test-id="discount-timer-item"
          >
            <div class="v-image">
              <arora-nuxt-image
                :alt="discount.ActionProductData.Name"
                :image="discount.ActionProductData.ProductMobileImage"
                image-type="ProductMobile"
              />
            </div>
            <div class="v-discount-timers-summary-right">
              <div
                class="v-title v-mb-sm"
                v-html="sanitize(discount.ActionProductData.Name)"
              />
              <div
                class="v-timer-block v-mb-sm"
                v-show="!appConfig.VueSettingsPreRun.DiscountTimerHideCountdown"
              >
                <div
                  class="v-timer-name v-mb-sm"
                  v-html="translate('discountTimersPage.timerName')"
                />
                <common-countdown :expires="discount.TimeEndSecTS" />
              </div>
              <div class="v-price-block">
                <arora-button
                  :label="translate('addToCartButton.addToCart')"
                  data-test-id="discount-timer-add-to-cart"
                  @click="
                    async () =>
                      await addToBasket(
                        discount.ActionProductData.ID,
                        discount.ID,
                        discount.GroupID,
                        discount.ActionProductData.Price,
                        1,
                        discount.ID
                      )
                  "
                >
                  <span class="v-price v-old">
                    <common-currency
                      strikethrough
                      :amount="
                        discount.CurrentProductData.HasOptions &&
                        discount.CurrentProductOptionID !== Guid.Empty
                          ? discount.CurrentProductData.Price +
                            discount.ProductsInfo[discount.CurrentProductOptionID].Price
                          : discount.CurrentProductData.Price
                      "
                    />
                  </span>
                  <span class="v-price">
                    <common-currency :amount="discount.ActionProductData.Price" />
                  </span>
                </arora-button>
              </div>
            </div>
          </div>
        </template>
      </arora-swiper-slider>
    </div>
  </div>
  <!-- end Dimholt -->
</template>

<script setup lang="ts">
import type { CurrentDiscount } from '~types/menuStore'

import type { GUID } from '@arora/common'

import { FirstAddType, Guid } from '~api/consts'

defineProps<{
  discountsData: CurrentDiscount[]
}>()

const { sanitize, translate } = useI18nSanitized()

const appConfig = useAppConfig()
const { addToCart } = useCartFunctionsWithDialogs()

const discountTimerBackground = computed<string>(() => {
  if (appConfig.VueSettingsPreRun.DiscountTimerBackground) {
    return `background-image: url(${appConfig.VueSettingsPreRun.DiscountTimerBackground.Path.replaceAll('\\', '/')});`
  }

  return 'background-image: none;'
})

async function addToBasket(
  ID: GUID,
  GroupId: GUID,
  Name: string,
  Price: number,
  Count: number,
  discountTimeItemId: GUID
): Promise<void> {
  await addToCart(ID, FirstAddType.Default, GroupId, Name, Price, null, null, Count, discountTimeItemId)
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.Belfalas {
  .v-discount-bg-title {
    background: variables.$DiscountTimerWrapperBackgroundColor;
    font-size: 1.5rem;
    padding: 15px;
    color: variables.$DiscountTimerWrapperColor;
    font-weight: bold;
    margin-bottom: -1px;
    position: relative;
    display: inline-flex;
    border-radius: variables.$BorderRadius 0 0 0;

    &:before {
      position: absolute;
      bottom: 0;
      right: -100%;
      content: ' ';
      width: 100%;
      height: 100%;
      background-image: var(--SvgWave);
      background-repeat: no-repeat;
    }
  }

  .v-discount-bg-container {
    flex: 0 0 100%;
    max-width: 100%;
    background: variables.$DiscountTimerWrapperBackgroundColor;
    padding: 15px;
    border-radius: 0 variables.$BorderRadius variables.$BorderRadius variables.$BorderRadius;

    .v-image-discount-absolute-wrapper {
      margin-bottom: 1.1rem;

      .v-image {
        position: relative;
        overflow: hidden;
        max-height: 150px;
        border-radius: variables.$BorderRadius variables.$BorderRadius variables.$BorderRadius 0;

        img {
          transform: translateY(-25%);
        }

        .v-timer-block {
          position: absolute;
          left: 0;
          bottom: 0;
          display: inline-flex;
          flex-direction: row;
          padding: 10px 0 0 15px;
          background: variables.$DiscountTimerWrapperBackgroundColor;

          .v-countdown-block {
            display: inline-flex;

            .v-countdown-colon {
              color: variables.$DiscountTimerColor;
            }
          }

          &:before {
            position: absolute;
            bottom: 0;
            right: -65px;
            content: ' ';
            width: 65px;
            height: 100%;
            background-image: var(--SvgWave);
            background-repeat: no-repeat;
          }
        }
      }
    }

    .v-discount-timers-summary-bottom-buy {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      align-items: center;

      .v-title,
      .v-price-block {
        padding-left: 15px;
        padding-right: 15px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .v-title {
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 5px;
        color: variables.$DiscountTimerWrapperColor;
      }

      .v-price-block {
        text-align: right;

        .v-price {
          font-size: 1.3rem;
          white-space: nowrap;

          &.v-old {
            font-size: 80%;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.Carchost {
  padding-top: 20px;
  background-repeat: repeat;
  background-color: variables.$DiscountTimerWrapperBackgroundColor;

  .v-discount-timer-title {
    color: variables.$DiscountTimerWrapperColor;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .v-discount-timer-description {
    color: variables.$DiscountTimerWrapperColor;
    font-size: 1.3rem;
  }

  .v-item-discount-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;

    .v-image-absolute {
      flex: 0 0 33%;
      max-width: 33%;
      position: relative;

      .v-image {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        overflow: hidden;
        border-radius: 15px 0 0 15px;

        img {
          height: 100%;
          width: auto;
          object-fit: cover;
        }
      }
    }

    .v-discount-timers-summary-right {
      flex: 0 0 67%;
      max-width: 67%;
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .v-title {
        flex-grow: 1;
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 5px;
        color: variables.$DiscountTimerWrapperColor;
      }

      .v-timer-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .v-timer-name {
          max-width: 70px;
          color: variables.$DiscountTimerWrapperColor;
          @include mixins.md {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        .v-countdown-block {
          margin: 0;

          .v-countdown-colon {
            color: variables.$DiscountTimerWrapperColor;
          }
        }
      }

      .v-price-block {
        text-align: right;
      }
    }
  }
}

.Dimholt {
  padding-top: 20px;
  background: variables.$DiscountTimerWrapperBackgroundColor;

  .v-swiper {
    padding: 40px;
    margin: -40px -40px -10px -40px;
    @include mixins.md {
      padding: 0;
      margin: 0;
    }
  }

  .v-discount-timer-title {
    color: variables.$DiscountTimerWrapperColor;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .v-discount-timer-description {
    color: variables.$DiscountTimerWrapperColor;
    font-size: 1.3rem;
  }

  .v-item-discount-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: variables.$BorderRadius;
    box-shadow: variables.$CardShadow;
    height: 100%;
    overflow: hidden;

    .v-image {
      flex: 0 0 33%;
      max-width: 33%;
    }

    .v-discount-timers-summary-right {
      flex: 0 0 67%;
      max-width: 67%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .v-title {
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 5px;
        color: variables.$DiscountTimerWrapperColor;
      }

      .v-timer-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .v-timer-name {
          max-width: 70px;
          @include mixins.md {
            max-width: 100%;
          }
        }

        .v-countdown-block {
          margin: 0;
        }
      }
    }
  }
}
</style>
